import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import {
  useLazyDeleteTicketByIdQuery,
  useLazyGetAllQueriesQuery,
  usePostReplyMutation,
} from "../../services/customerSupport";
import { QueryResponse } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { Pagination, WarnModal } from "../../components";
import { isValidInput } from "../../utils/validations";
import { useNavigate } from "react-router-dom";
import { isString, useFormik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerSupport = () => {
  const userData = useAuth();
  const navigate = useNavigate();

  const [openQueryModal, setOpenQueryModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [queriesList, setQueriesList] = useState<QueryResponse[]>([]);
  const [getQuesriesList, { isLoading }] = useLazyGetAllQueriesQuery();
  const [postReply] = usePostReplyMutation();
  const [deleteTicketById] = useLazyDeleteTicketByIdQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const checkPermission = () => {
    const permission = userData?.permissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Customer Support"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getQueries = async () => {
    try {
      const response = await getQuesriesList({
        status: value === 0 ? "PENDING" : "RESOLVED",
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setQueriesList(response?.data?.ticket || []);
        setTotalCount(response?.data?.ticketCount);
      } else {
        setQueriesList([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteTicketById({ query_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Query Deleted Successfully");
        const res = await getQuesriesList({
          status: value === 0 ? "PENDING" : "RESOLVED",
          page: page,
          limit: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setQueriesList(res?.data?.ticket || []);
          // setTotalCount(response?.data?.ticketCount);
        } else {
          setQueriesList([]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getQueries();
  }, [value, debouncedSearchTerm, page]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        message: formik.values.message,
      };
      try {
        const response = await postReply({
          query_id: selectedId,
          body,
        }).unwrap();
        showToast(response?.message || "");
        setOpenQueryModal(false);
        formik.setFieldValue("message", "");
        formik.setFieldError("message", "");
        const res = await getQuesriesList({
          status: value === 0 ? "PENDING" : "RESOLVED",
          page: page,
          limit: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setQueriesList(res?.data?.ticket || []);
          setTotalCount(res?.data?.ticketCount);
        } else {
          setQueriesList([]);
        }
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  const handleClickOpen = (id: string) => {
    setOpenQueryModal(true);
    setSelectedId(id);
  };
  const handleClose = () => {
    setOpenQueryModal(false);
    formik.setFieldError("message", "");
  };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Customer Support</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Queries Pending" {...a11yProps(0)} />
              <Tab label="Queries Resolved" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      {/* <TableCell>Customer Name</TableCell> */}
                      <TableCell>Email</TableCell>
                      <TableCell>Issue Type</TableCell>
                      {/* <TableCell>Wallet Address</TableCell> */}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {queriesList?.length ? (
                    queriesList?.map((row, i) => (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">
                            {" "}
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          {/* <TableCell>{row.Name}</TableCell> */}
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.subject || "-"}</TableCell>

                          {/* <TableCell>{row?.walletAddress || "-"}</TableCell> */}
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/customer-support/details/${row?._id}`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              {hidePermission?.isEdit ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() => handleClickOpen(row?._id)}
                                >
                                  <ReplyIcon />
                                </IconButton>
                              ) : null}
                              {hidePermission?.isDelete ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      {" "}
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "white" }}
                      >
                        No Queries found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                />
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Issue Type</TableCell>
                      {/* <TableCell>Description</TableCell> */}
                      {/* <TableCell>Wallet Address</TableCell> */}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {queriesList?.length ? (
                    queriesList?.map((row, i) => (
                      <TableBody key={row?._id}>
                        <TableRow>
                          <TableCell align="center">
                            {" "}
                            {(page - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>{row?.email || "-"}</TableCell>
                          <TableCell>{row?.subject || "-"}</TableCell>

                          {/* <TableCell>{row?.walletAddress || "-"}</TableCell> */}
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/customer-support/details/${row?._id}`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              {hidePermission?.isDelete ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      {" "}
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "white" }}
                      >
                        No Queries found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>

      <Dialog
        open={openQueryModal}
        onClose={handleClose}
        className="revert_dialog"
      >
        <DialogContent>
          <Typography className="dialog_title">Revert Query</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="message"
                type="text"
                placeholder="Revert Query"
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.message}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.message && formik.errors.message}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_danger" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Pagination
        module={queriesList}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Query"
      />
    </MainContainer>
  );
};

export default CustomerSupport;
