import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LineChart from "../../components/LineChart";
import { useNavigate } from "react-router-dom";
import { useLazyGetDashboardQuery } from "../../services/main";
import { DashboardData } from "../../types/General";
import { showError } from "../../constants";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#252525" : "#252525",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const [userFilter, setUserFilter] = useState<string>("monthly");

  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<number[]>([]);
  const [transactionFilter, setTransactionFilter] = useState<string>("monthly");
  const [transactionName, setTransactionName] = useState<string[]>([]);
  const [transactionValues, setTransactionValues] = useState<number[]>([]);

  const handleUserChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleTransactionChange = (event: SelectChangeEvent) => {
    setTransactionFilter(event.target.value as string);
  };

  const graphCustomerReportData = {
    labels: userNames,
    datasets: [
      {
        label: "Customer Reports",
        data: userValues,
        borderColor: "#cdff00",
        backgroundColor: "#cdff00",
      },
    ],
  };
  const graphTransactionReportsData = {
    labels: transactionName,
    datasets: [
      {
        label: "Transactions report",
        data: transactionValues,
        borderColor: "#cdff00",
        backgroundColor: "#cdff00",
      },
    ],
  };
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [getDashBoardData] = useLazyGetDashboardQuery();

  const navigate = useNavigate();

  const getDashBoard = async () => {
    try {
      const response = await getDashBoardData({
        user: userFilter,
        transactions: transactionFilter,
        earning: "",
        investment: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setDashboardData(response?.data);
        setUserNames(Object.keys(response?.data?.userData));
        setUserValues(Object.values(response?.data?.userData));
        setTransactionName(Object.keys(response?.data?.transactionData));
        setTransactionValues(Object.values(response?.data?.transactionData));
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getDashBoard();
  }, [userFilter, transactionFilter]);

  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1>Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Users</h3>
              <h4>{dashboardData?.totalUser || "-"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-properties")}
          >
            <WysiwygIcon className="svg_icn" />
            <div>
              <h3>Total Properties</h3>
              <h4>{dashboardData?.totalProperties || "-"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manage-revenue")}
          >
            <AttachMoneyIcon className="svg_icn" />
            <div>
              <h3>Total tokens </h3>
              <h4>{dashboardData?.totalTokens[0]?.total || "-"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageservices")}
          >
            <GroupAddIcon className="svg_icn" />
            <div>
              <h3>New users</h3>
              <h4>{dashboardData?.newUsers || "-"}</h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item xs={6}>
          <Item className="cards dashGraph_item">
            <h2>
              Customer Reports
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleUserChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphCustomerReportData} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item className="cards dashGraph_item">
            <h2>
              Transaction Reports{" "}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={transactionFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleTransactionChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphTransactionReportsData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
