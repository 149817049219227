import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import MainContainer from "../../../layout/MainContainer";
import UserPurchasedProperties from "./PurchasedProperties";
import CloseIcon from "@mui/icons-material/Close";

import {
  useApproveUserByIdMutation,
  useLazyGetAllListedPropertyByUserQuery,
  useLazyGetAllPurchasedPropertyByUserQuery,
  useLazyGetUserByIdQuery,
  useRejectUserByIdMutation,
} from "../../../services/main";
import { Loader, showError, showToast } from "../../../constants";
import {
  GetUserById,
  ListedPropertiesByUserId,
  PurchasedPropertiesByUserId,
} from "../../../types/General";
import UserListedProperties from "./ListedProperties";
import { isString, useFormik } from "formik";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openFrontImage, setOpenFrontImage] = useState(false);
  const [openBackImage, setOpenBackImage] = useState(false);
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [userData, setUserData] = useState<GetUserById>();

  const [listedProperties, setListedProperties] = useState<
    ListedPropertiesByUserId[]
  >([]);
  const [purchasedProperties, setPurchasedProperties] = useState<
    PurchasedPropertiesByUserId[]
  >([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(1);

  const [getUserById, { isLoading }] = useLazyGetUserByIdQuery();
  const [approveUser] = useApproveUserByIdMutation();
  const [rejectUser] = useRejectUserByIdMutation();
  const [listedPropertiesByUser] = useLazyGetAllListedPropertyByUserQuery();
  const [purchasedPropertiesByUser] =
    useLazyGetAllPurchasedPropertyByUserQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setValue2(0);
  };
  const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setValue2(newValue);
  };
  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({
        user_id: id,
        // status: value,
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const approveUserById = async (id: string) => {
    try {
      const response = await approveUser({
        user_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast("User Approved Successfully");
        navigate("/manageusers");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getAllListedPropertiesByUser = async (id: string) => {
    try {
      const response = await listedPropertiesByUser({
        user_id: id,
        investmentType: value2 === 0 ? "Ownership" : "Profit Sharing",
        page: page,
        limit: 10,
        // query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setListedProperties(response?.data?.property || []);
        setTotalCount(response?.data?.propertyCount);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getAllPurchasedPropertiesByUser = async (id: string) => {
    try {
      const response = await purchasedPropertiesByUser({
        user_id: id,
        investmentType: value2 === 0 ? "Ownership" : "Profit Sharing",
        page: page,
        limit: 10,
        // query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log("response: ", response);
        setPurchasedProperties(response?.data?.result || []);
        setTotalCount(response?.data?.propertyCount);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (id) {
      getUserDetail(id);
    }
  }, []);

  useEffect(() => {
    if (id && value === 1) {
      getAllListedPropertiesByUser(id);
    } else if (id && value === 0) {
      getAllPurchasedPropertiesByUser(id);
    }
  }, [value, value2, page]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rejectReason: "",
    },
    validationSchema: Yup.object({
      rejectReason: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        rejectReason: formik.values.rejectReason,
      };
      try {
        const response = await rejectUser({
          user_id: id as string,
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast("User Rejected Successfully");
          setOpenRejectModal(false);
          formik.setFieldValue("rejectReason", "");
          formik.setFieldError("rejectReason", "");
          navigate("/manageusers");
        }
      } catch (error: any) {
        console.log(error, "errror");
        showError(error?.data?.message || "");
      }
    },
  });

  const handleClickOpen = () => {
    setOpenRejectModal(true);
  };
  const handleClose = () => {
    setOpenRejectModal(false);
    formik.setFieldError("rejectReason", "");
  };
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageusers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={2}>
                <figure className="profile_img">
                  <img
                    src={
                      userData?.profilePic
                        ? userData?.profilePic
                        : "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Firstname</Typography>
                      <Typography component="p">
                        {userData?.firstName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Lastname</Typography>
                      <Typography component="p">
                        {userData?.lastName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">
                        {userData?.countryCode
                          ? (userData?.countryCode.includes("+") ? "" : "+") +
                            userData?.countryCode
                          : null}{" "}
                        {userData?.phone || ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {userData?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">User Status</Typography>
                      <Typography component="p">
                        {userData?.userStatus || "-"}
                        {userData?.reDocumentVerfication ? "(Reapplied)" : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">
                        {userData?.isBlocked ? "Deactivated" : "Active" || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Total Earnings</Typography>
                      <Typography component="p">
                        {userData?.totalEarning || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">
                        Available wallet tokens
                      </Typography>
                      <Typography component="p">
                        {userData?.totalTokens || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Tokens Purchased</Typography>
                      <Typography component="p">
                        {userData?.purchasedTokens || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Tokens Sold</Typography>
                      <Typography component="p">
                        {userData?.soldTokens || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  {userData?.document !== "" ? (
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Document Type</Typography>
                        <Typography component="p">
                          {userData?.document || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}

                  <Grid item xs={3} />

                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Wallet Address</Typography>

                      <Typography component="p">
                        {userData?.walletAddress || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  {userData?.document !== "" ? (
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Documents</Typography>
                        <Box className="docs_div">
                          <figure>
                            <VisibilityIcon
                              onClick={() => setOpenFrontImage(true)}
                            />

                            <img src={userData?.documentFrontImage} alt="" />
                          </figure>
                          <figure>
                            <VisibilityIcon
                              onClick={() => setOpenBackImage(true)}
                            />
                            <img src={userData?.documentBackImage} alt="" />
                          </figure>
                        </Box>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
                {userData?.userStatus === "PENDING" ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          padding: "20px 0 0",
                          fontSize: "20px",
                          color: "#cdff00",
                          fontWeight: "700",
                        }}
                        component="h2"
                      >
                        Approval
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        // disabled={
                        //   propertyData?.propertyStatus === "DISAPPROVED" ||
                        //   propertyData?.propertyStatus === "APPROVED"
                        //     ? true
                        //     : false
                        // }
                        onClick={() => approveUserById(id as string)}
                      >
                        Approve
                      </Button>
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        // disabled={
                        //   propertyData?.propertyStatus === "DISAPPROVED" ||
                        //   propertyData?.propertyStatus === "APPROVED"
                        //     ? true
                        //     : false
                        // }
                        // onClick={() => rejectUserById(id as string)}
                        onClick={handleClickOpen}
                      >
                        Disapprove
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {userData?.userStatus === "PENDING" ||
        userData?.userStatus === "REJECTED" ? null : (
          <Card sx={{ mt: 4 }} className="cards">
            <Box className="custom_tabs">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Purchased Properties" {...a11yProps(0)} />
                <Tab label="Listed Properties" {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel value={value} index={0}>
                <Box className="cards_header">
                  <Box sx={{ width: "100%", marginBottom: 3 }}>
                    <Box sx={{ borderBottom: 1, borderColor: "#ffffff6b" }}>
                      <Tabs
                        aria-label="basic tabs example"
                        className="inner_tabs"
                        value={value2}
                        onChange={handleChange2}
                      >
                        <Tab label="Ownership" {...a11yProps(0)} />
                        <Tab label="Profit Sharing" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                  </Box>
                  <br />
                </Box>

                <UserPurchasedProperties
                  value2={value2}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  purchasedProperties={purchasedProperties}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box className="cards_header">
                  <Box sx={{ width: "100%", marginBottom: 3 }}>
                    <Box sx={{ borderBottom: 1, borderColor: "#ffffff6b" }}>
                      <Tabs
                        aria-label="basic tabs example"
                        className="inner_tabs"
                        value={value2}
                        onChange={handleChange2}
                      >
                        <Tab label="Ownership" {...a11yProps(0)} />
                        <Tab label="Profit Sharing" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                  </Box>

                  <br />
                </Box>
                <UserListedProperties
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  value2={value2}
                  listedProperties={listedProperties}
                />
              </CustomTabPanel>
            </Box>
          </Card>
        )}
      </div>
      <Modal
        className="modal docs_modal"
        open={openFrontImage}
        closeAfterTransition
      >
        {/* <Fade in={openFrontImage}> */}
        <Box className="modalBox_body">
          <Box>
            <Button
              className="close_btn"
              onClick={() => setOpenFrontImage(false)}
            >
              <CloseIcon />
            </Button>

            <figure style={{ height: "100%", width: "80%", margin: "0 auto" }}>
              <img
                src={userData?.documentFrontImage || ""}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </figure>
          </Box>
        </Box>
      </Modal>
      <Modal
        className="modal docs_modal"
        open={openBackImage}
        closeAfterTransition
      >
        <Box className="modalBox_body">
          <Box>
            <Button
              className="close_btn"
              onClick={() => setOpenBackImage(false)}
            >
              <CloseIcon />
            </Button>

            <figure style={{ height: "100%", width: "80%", margin: "0 auto" }}>
              <img
                src={userData?.documentBackImage || ""}
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </figure>
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={openRejectModal}
        onClose={handleClose}
        className="revert_dialog"
      >
        <DialogContent>
          <Typography className="dialog_title">Reason for Rejection</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="rejectReason"
                name="rejectReason"
                type="text"
                placeholder="Enter Reason"
                multiline
                rows={4}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.rejectReason}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={
                  formik.touched.rejectReason && formik.errors.rejectReason
                }
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_danger" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default UserDetails;
