import { END_POINTS } from "../constants/url";
import {
  NewsByIdResponse,
  Newstype,
  QueryDetailResponse,
  ReportRes,
  TransactionsResponse,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllNewsResponse = {
  newsCount: number;
  news: Newstype[];
};

type GetNewsResponse = {
  title: string;
  description: string;
  newsImage: string;
  isDeleted: boolean;
  isBlocked: boolean;
  _id: string;
  createdAt: string;
};

type PostNewsBody = {
  title: string;
  description: string;
  newsImage: string;
};

type TransactionsType = {
  transactionCount: number;
  transaction: TransactionsResponse[];
};

type ReportType = {
  reportCount: number;
  report: ReportRes[];
};

type ReviewType = {
  reviewCount: number;
  review: ReportRes[];
};

export const blogsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNews: builder.query<
      CommonResponseType & { data: GetAllNewsResponse },
      { page: number; limit: number; query: string }
    >({
      query: ({ page, limit, query }) => ({
        url:
          END_POINTS.getAllNews +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    postNews: builder.mutation<
      CommonResponseType & { data: GetNewsResponse },
      PostNewsBody
    >({
      query: (body) => ({
        url: `${END_POINTS.createNews}`,
        method: "POST",
        body,
      }),
    }),
    changeNewsStatus: builder.query<
      CommonResponseType & { data: GetAllNewsResponse },
      { news_id: string }
    >({
      query: ({ news_id }) => ({
        url: `${END_POINTS.blockUnblockNews}/${news_id}`,
        method: "GET",
      }),
    }),
    getNewsById: builder.query<
      CommonResponseType & { data: NewsByIdResponse[] },
      { news_id: string }
    >({
      query: ({ news_id }) => ({
        url: `${END_POINTS.getNewsById}/${news_id}`,
        method: "GET",
      }),
    }),
    updateNews: builder.mutation<
      CommonResponseType & { data: GetNewsResponse },
      { body: PostNewsBody; news_id: string }
    >({
      query: ({ body, news_id }) => ({
        url: `${END_POINTS.updateNews}/${news_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteNewsById: builder.query<
      CommonResponseType & { data: GetAllNewsResponse },
      { news_id: string }
    >({
      query: ({ news_id }) => ({
        url: `${END_POINTS.deleteNews}/${news_id}`,
        method: "DELETE",
      }),
    }),
    getAllTransactions: builder.query<
      CommonResponseType & { data: TransactionsType },
      { page: number; limit: number; query: string }
    >({
      query: ({ page, limit, query }) => ({
        url:
          END_POINTS.getAllTransactions +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getAllReport: builder.query<
      CommonResponseType & { data: ReportType },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getAllReport}/${id}`,
        method: "GET",
      }),
    }),
    getAllReviews: builder.query<
      CommonResponseType & { data: ReviewType },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.review}/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllNewsQuery,
  usePostNewsMutation,
  useLazyChangeNewsStatusQuery,
  useLazyGetNewsByIdQuery,
  useLazyDeleteNewsByIdQuery,
  useUpdateNewsMutation,
  useLazyGetAllTransactionsQuery,
  useLazyGetAllReportQuery,
  useLazyGetAllReviewsQuery,
} = blogsApi;
