import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import MainContainer from "../../layout/MainContainer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TransactionDetails = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setValue2(0);
  };
  const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setValue2(newValue);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-transactions");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={2}>
                <figure className="profile_img">
                  <img src="/static/images/user_placeholder.png" alt="" />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Username</Typography>
                      <Typography component="p">Abdul67</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">+971056734321</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        abdulhussian67@gmail.com
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Earnings</Typography>
                      <Typography component="p">600 SAR</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">Active</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Total Earnings</Typography>
                      <Typography component="p">300</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">
                        Available wallet tokens
                      </Typography>
                      <Typography component="p">300</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Tokens Purchased</Typography>
                      <Typography component="p">10</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Tokens Sold</Typography>
                      <Typography component="p">10</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Purchased Properties" {...a11yProps(0)} />
              <Tab label="Listed Properties" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <Box sx={{ width: "100%", marginBottom: 3 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "#ffffff6b" }}>
                    <Tabs
                      aria-label="basic tabs example"
                      className="inner_tabs"
                      value={value2}
                      onChange={handleChange2}
                    >
                      <Tab label="Ownership" {...a11yProps(0)} />
                      <Tab label="Profit Sharing" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                </Box>
                <br />
              </Box>

              <UserPurchasedProperties value2={value2} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <Box sx={{ width: "100%", marginBottom: 3 }}>
                  <Box sx={{ borderBottom: 1, borderColor: "#ffffff6b" }}>
                    <Tabs
                      aria-label="basic tabs example"
                      className="inner_tabs"
                      value={value2}
                      onChange={handleChange2}
                    >
                      <Tab label="Ownership" {...a11yProps(0)} />
                      <Tab label="Profit Sharing" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                </Box>

                <br />
              </Box>
              <UserListedProperties value2={value2} />
            </CustomTabPanel>
          </Box>
        </Card> */}
      </div>
    </MainContainer>
  );
};

export default TransactionDetails;
