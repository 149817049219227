import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Backdrop,
  Fade,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import {
  useApprovePropertyMutation,
  useLazyGetRequestedPropertyByIdQuery,
  useLazyRejectPropertyQuery,
} from "../../../services/main";
import { Loader, showError, showToast } from "../../../constants";
import { RequestedProperty } from "../../../types/General";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { ContractAddress, ABI } from "../../../utils/configuration";
import Web3 from "web3";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ReportDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [open_gallery, setOpen_gallery] = useState(false);
  const [openVideos, setOpenVideos] = useState(false);
  const [contract, setContract] = useState<any>();
  const [propertyData, setPropertyData] = useState<RequestedProperty>();

  const [getRequestedPropertById, { isLoading }] =
    useLazyGetRequestedPropertyByIdQuery();

  const [approveProperty] = useApprovePropertyMutation();
  const [rejectProperty] = useLazyRejectPropertyQuery();

  const getRequestedPropertyDetail = async (id: string) => {
    try {
      const response = await getRequestedPropertById({
        property_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setPropertyData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  const approveRequestedProperty = async (id: string) => {
    try {
      const response = await approveProperty({
        property_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
        navigate("/manage-requests");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  const rejectRequestedProperty = async (id: string) => {
    try {
      const response = await rejectProperty({
        property_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
        const res = await getRequestedPropertById({
          property_id: id,
        }).unwrap();
        if (res?.statusCode === 200) {
          setPropertyData(res?.data[0]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (id) {
      getRequestedPropertyDetail(id);
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      if (window.ethereum !== "undefined") {
        try {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const web3 = new Web3(window.ethereum);
          const contractInstance = new web3.eth.Contract(ABI, ContractAddress);

          setContract(contractInstance);

          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const account = accounts[0];
          // setToStorage(STORAGE_KEYS.walletAddress, account || null);
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        console.error("Please install MetaMask or any other Web3 provider.");
      }
    };
    init();
  }, []);

  const handleApprove = async () => {
    console.log("jjj");
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      console.log(account, "8888888");
      if (contract) {
        console.log("contract: ", contract);
        const gas = await contract.methods
          .approveForMinting(
            propertyData?.userWalletAddress,
            propertyData?.totalTokens,
            Math.round(propertyData?.tokenValue as number),
            propertyData?.propertyId
          )
          .estimateGas({ from: account });
        console.log("gas: ", gas);

        let approveForMinting = await contract.methods

          .approveForMinting(
            propertyData?.userWalletAddress,
            propertyData?.totalTokens,
            Math.round(propertyData?.tokenValue as number),
            propertyData?.propertyId
          )
          .send({ from: account, gas: parseInt(gas) });
        console.log("approveForMinting: ", approveForMinting);
        if (approveForMinting) {
          approveRequestedProperty(id as string);
        }
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "");
    }
  };

  function getDaySuffix(day: number) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Requested Property</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-requests");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              {state?.propertyType === "Profit Sharing" ? (
                <Grid item xs={10} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">User Details</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Username</Typography>
                        <Typography component="p">
                          {`${propertyData?.firstName} ${propertyData?.lastName}` ||
                            "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Email</Typography>
                        <Typography component="p">
                          {propertyData?.email || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Contact No</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.countryCode
                            ? (propertyData?.countryCode.includes("+")
                                ? ""
                                : "+") + propertyData?.countryCode
                            : null}{" "}
                          {propertyData?.phone || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Details</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Title</Typography>
                        <Typography component="p">
                          {propertyData?.propertyTitle || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Location</Typography>
                        <Typography component="p">
                          {propertyData?.propertyAddress || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Property Type</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.propertyType || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">No. of Tokens</Typography>
                        <Typography component="p">
                          {propertyData?.totalTokens}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Profit Expected</Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.expectedProfit}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Profit Received</Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.profitRecieved}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">
                          Date of profit distribution
                        </Typography>
                        <Typography component="p">
                          {/* {moment(propertyData?.profitDistributionDate).format(
                            "DD/MM/YYYY"
                          )} */}
                          {propertyData?.profitDistributionDate || ""}
                          {getDaySuffix(
                            Number(propertyData?.profitDistributionDate)
                          )}{" "}
                          of every month
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">No of rooms</Typography>
                        <Typography component="p">
                          {propertyData?.bedroomsCount || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Approval Status</Typography>
                        <Typography component="p">
                          {propertyData?.propertyStatus || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Description</Typography>
                        <Typography component="p">
                          {propertyData?.propertyDescription || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">
                          Uploaded Documents
                        </Typography>
                        <Box className="docs_div">
                          {propertyData?.ownershipDocuments?.length
                            ? propertyData?.ownershipDocuments?.map((item) => {
                                return (
                                  <figure className="doc_img">
                                    <img src={item} alt="" />
                                  </figure>
                                );
                              })
                            : null}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Images</Typography>
                      </Box>
                      <Box className="other_gallery">
                        <figure>
                          <img
                            src={propertyData?.propertyImages?.[0] || ""}
                            alt=""
                          />
                          <span
                            className="more"
                            onClick={() => setOpen_gallery(true)}
                          >
                            <img src="/images/icon_image.svg" alt="" />
                            Show all photos
                          </span>
                        </figure>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Videos</Typography>
                      </Box>
                      <Box className="other_gallery">
                        <figure>
                          <img
                            src={propertyData?.propertyImages?.[1] || ""}
                            alt=""
                          />
                          <span
                            className="more"
                            onClick={() => setOpenVideos(true)}
                          >
                            <img src="/images/icon_image.svg" alt="" />
                            Show all videos
                          </span>
                        </figure>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={10} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">User Details</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Username</Typography>
                        <Typography component="p">
                          {" "}
                          {`${propertyData?.firstName} ${propertyData?.lastName}` ||
                            "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Email</Typography>
                        <Typography component="p">
                          {propertyData?.email || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Contact No</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.phone || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Details</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Title</Typography>
                        <Typography component="p">
                          {propertyData?.propertyTitle || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Location</Typography>
                        <Typography component="p">
                          {propertyData?.propertyAddress || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Property Type</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.propertyType || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Total Amount</Typography>
                        <Typography component="p">
                          {`USDT ${propertyData?.totalInvestment}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">
                          Milestone 1 (Upfront)
                        </Typography>
                        <Typography component="p">
                          {`USDT ${propertyData?.milestoneAmount1}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">
                          Milestone 2 (Contract Signing)
                        </Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.milestoneAmount2}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">
                          Milestone 3 (Contract Transfer)
                        </Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.milestoneAmount3}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Approval Status</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.propertyStatus || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Description</Typography>
                        <Typography component="p">
                          {propertyData?.propertyDescription || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">
                          Uploaded Documents
                        </Typography>
                        <Box className="docs_div">
                          {propertyData?.ownershipDocuments?.length
                            ? propertyData?.ownershipDocuments?.map((item) => {
                                return (
                                  <figure className="doc_img">
                                    <img src={item} />
                                  </figure>
                                );
                              })
                            : null}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Images</Typography>
                      </Box>
                      <Box className="other_gallery">
                        <figure>
                          <img
                            src={propertyData?.propertyImages?.[0] || ""}
                            alt=""
                          />
                          <span
                            className="more"
                            onClick={() => setOpen_gallery(true)}
                          >
                            <img src="/images/icon_image.svg" alt="" />
                            Show all photos
                          </span>
                        </figure>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Videos</Typography>
                      </Box>
                      <Box className="other_gallery">
                        <figure>
                          <img
                            src={propertyData?.propertyImages?.[1] || ""}
                            alt=""
                          />
                          <span
                            className="more"
                            onClick={() => setOpenVideos(true)}
                          >
                            <img src="/images/icon_image.svg" alt="" />
                            Show all videos
                          </span>
                        </figure>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Box>
              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Interior features</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>Builtin Features</th>
                      <td>{propertyData?.BuiltInFeature || "-"}</td>
                      <th>Separate Formal Dining Room</th>
                      <td>{propertyData?.SeparateFormalDiningRoom || "-"}</td>
                    </tr>
                    <tr>
                      <th>Bedroom Main Level</th>
                      <td>{propertyData?.bedroomMainLevel || "-"}</td>
                      <th>Dual Sinks</th>
                      <td>{propertyData?.dualSink || "-"}</td>
                    </tr>
                    <tr>
                      <th>Dining Area</th>
                      <td>{propertyData?.diningArea || "-"}</td>
                      <th>Separate Shower</th>
                      <td>{propertyData?.separateShower || "-"}</td>
                    </tr>
                    <tr>
                      <th>Kitchen Island</th>
                      <td>{propertyData?.kitchenIsland || "-"}</td>
                      <th>Upper Level Primary</th>
                      <td>{propertyData?.upperLevelPrimary || "-"}</td>
                    </tr>
                    <tr>
                      <th>Pantry</th>
                      <td>{propertyData?.pantry || "-"}</td>
                      <th>Bar</th>
                      <td>{propertyData?.Bar || "-"}</td>
                    </tr>
                    <tr>
                      <th>Sitting Area Primary</th>
                      <td>{propertyData?.sittingArea || "-"}</td>
                      <th>Walk In Closets</th>
                      <td>{propertyData?.walkInClosets || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Appliances</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>Dishwasher</th>
                      <td>{propertyData?.dishwasher || "-"}</td>
                      <th>Oven</th>
                      <td>{propertyData?.Oven || "-"}</td>
                    </tr>
                    <tr>
                      <th>Dryer</th>
                      <td>{propertyData?.dryer || "-"}</td>
                      <th>Range</th>
                      <td>{propertyData?.Range || "-"}</td>
                    </tr>
                    <tr>
                      <th>Ice Maker</th>
                      <td>{propertyData?.iceMaker || "-"}</td>
                      <th>Refrigerator</th>
                      <td>{propertyData?.refrigerator || "-"}</td>
                    </tr>
                    <tr>
                      <th>Microwave</th>
                      <td>{propertyData?.microwave || "-"}</td>
                      <th>Washer</th>
                      <td>{propertyData?.washingMachine || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {propertyData?.exteriorLotFeatures?.length ? (
                <div className="box w_100">
                  <h3 style={{ color: "#cdff00" }}>
                    Exterior and lot features
                  </h3>
                  <table>
                    <tbody>
                      {propertyData?.exteriorLotFeatures?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
              {propertyData?.parkingFeatures?.length ? (
                <div className="box w_100">
                  <h3 style={{ color: "#cdff00" }}>Parking features</h3>
                  <table>
                    <tbody>
                      {propertyData?.parkingFeatures?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}

              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Building and Construction</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>Year built</th>
                      <td>
                        {moment(propertyData?.YearBuilt).format("YYYY") || "-"}
                      </td>
                      <th>Flooring</th>
                      <td style={{ wordWrap: "break-word" }}>
                        {propertyData?.Flooring || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Construction materials</th>
                      <td style={{ wordWrap: "break-word" }}>
                        {propertyData?.ContructionMeterial || "-"}
                      </td>
                      <th>Roof</th>
                      <td style={{ wordWrap: "break-word" }}>
                        {propertyData?.Roof || "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Flooring</th>
                      <td>{propertyData?.Flooring || "-"}</td>
                      <th>Architectural style</th>
                      <td>{propertyData?.ArchitecturalStyle || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Other</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>View</th>
                      <td>{propertyData?.View || "-"}</td>
                      <th>Waterfront features</th>
                      <td>{propertyData?.WaterFrontFeature || "-"}</td>
                    </tr>
                    <tr>
                      <th>Waterfront</th>
                      <td>{propertyData?.WaterFront || "-"}</td>
                      <th>Accessibility features</th>
                      <td>{propertyData?.AccessibilityFeature || "-"}</td>
                    </tr>
                    <tr>
                      <th>Taxes</th>
                      <td>{propertyData?.Taxes || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    padding: "20px 0 0",
                    fontSize: "20px",
                    color: "#cdff00",
                    fontWeight: "700",
                  }}
                  component="h2"
                >
                  Approval
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  disabled={
                    propertyData?.propertyStatus === "DISAPPROVED" ||
                    propertyData?.propertyStatus === "APPROVED"
                      ? true
                      : false
                  }
                  onClick={handleApprove}
                >
                  Approve
                </Button>
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  disabled={
                    propertyData?.propertyStatus === "DISAPPROVED" ||
                    propertyData?.propertyStatus === "APPROVED"
                      ? true
                      : false
                  }
                  onClick={() => rejectRequestedProperty(id as string)}
                >
                  Disapprove
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <Modal
        className="modal gallery_modal"
        open={open_gallery}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open_gallery}>
          <Box className="modalBox_body">
            <Box>
              <Button
                className="close_btn"
                onClick={() => setOpen_gallery(false)}
              >
                <CloseIcon />
              </Button>
              <Swiper
                className="location_swiper gallery_swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  clickable: true,
                }}
                loop
                observer
              >
                <div className="swiper_action">
                  <div className="swiper-button-prev">
                    <ArrowBackIcon />
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next">
                    <ArrowForwardIcon />
                  </div>
                </div>
                {propertyData?.propertyImages?.length
                  ? propertyData?.propertyImages?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <figure>
                          <img
                            src={item || "/images/property-detail-01.jpg"}
                            alt=""
                          />
                        </figure>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Modal
        className="modal gallery_modal"
        open={openVideos}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openVideos}>
          <Box className="modalBox_body">
            <Box>
              <Button
                className="close_btn"
                onClick={() => setOpenVideos(false)}
              >
                <CloseIcon />
              </Button>
              <Swiper
                className="location_swiper gallery_swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  clickable: true,
                }}
                loop
                observer
              >
                <div className="swiper_action">
                  <div className="swiper-button-prev">
                    <ArrowBackIcon />
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next">
                    <ArrowForwardIcon />
                  </div>
                </div>
                {propertyData?.propertyVideos?.length
                  ? propertyData?.propertyVideos?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <figure>
                          <video autoPlay muted loop playsInline preload="yes">
                            <source
                              src={item || "/images/property-detail-01.jpg"}
                              type="video/mp4"
                            />
                          </video>
                        </figure>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </MainContainer>
  );
};

export default ReportDetail;
