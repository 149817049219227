import { END_POINTS } from "../constants/url";
import {
  AllRequestedProperties,
  AllUser,
  DashboardData,
  GetUserById,
  ListedPropertiesByUserId,
  ProfitHistoryType,
  PurchasedPropertiesByUserId,
  RequestedProperty,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllUsersResponse = {
  userCount: number;
  user: AllUser[];
};
type ApprovedUserResponse = {
  data: {
    message: string;
  };
};

type GetUsersParams = {
  user_id: string;
  // status: number;
  page: number;
  query: string;
  limit: number;
};
type GetTokenParams = {
  status?: string;
  limit: number;
  page: number;
  query: string;
  // status?: number;
};
type EditUserById = {
  email: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  phone: string;
  profilePic: string;
};
type GetRequestedPropertiesResponse = {
  propertyCount: number;
  property: AllRequestedProperties[];
};
type ListedPropertiesByUser = {
  propertyCount: number;
  property: ListedPropertiesByUserId[];
};
type PurchasedPropertiesByUser = {
  propertyCount: number;
  result: PurchasedPropertiesByUserId[];
};

type GetRequestedPropertyByIdParams = {
  property_id: string;
};
type GetUserStatusResponse = {
  isBlocked: boolean;
};
type ApprovedPropertyResponse = {
  data: {
    message: string;
  };
};
type PropertByUserParams = {
  user_id: string;
  investmentType: string;
  page: number;
  limit: number;
};
type ProfitHistoryResponse = {
  profitCount: number;
  profit: ProfitHistoryType[];
};
type PostRejectUser = {
  body: {
    rejectReason: string;
  };
  user_id: string;
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<
      CommonResponseType & { data: DashboardData },
      {
        user: string;
        transactions: string;
        earning: string;
        investment: string;
      }
    >({
      query: ({ user, transactions, earning, investment }) => ({
        url: `${END_POINTS.getDashboardData}?user=${user}&transactions=${transactions}&earning=${earning}&investment=${investment}`,
        method: "GET",
      }),
    }),
    getAllUsers: builder.query<
      CommonResponseType & { data: GetAllUsersResponse },
      GetTokenParams
    >({
      query: ({ status, limit, page, query }) => ({
        url:
          END_POINTS.getAllUsers +
          "?userStatus=" +
          status +
          "&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getUserCSV: builder.query<CommonResponseType & { data: string }, {}>({
      query: () => ({
        url: END_POINTS.exportUserCSV,
        method: "GET",
      }),
    }),
    changeUserStatus: builder.query<
      CommonResponseType & { data: GetUserStatusResponse },
      { user_id: string }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.user_status}/${user_id}/`,
        method: "GET",
      }),
    }),
    getUserById: builder.query<
      CommonResponseType & { data: GetUserById[] },
      GetUsersParams
    >({
      query: ({ user_id, page, query, limit }) => ({
        url: `${END_POINTS.getUserById}/${user_id}?page=${page}&limit=${limit}&search=${query}`,
        method: "GET",
      }),
    }),
    approveUserById: builder.mutation<
      CommonResponseType & { data: ApprovedUserResponse },
      { user_id: string }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.approveUser}/${user_id}`,
        method: "POST",
      }),
    }),
    rejectUserById: builder.mutation<
      CommonResponseType & { data: ApprovedUserResponse },
      PostRejectUser
    >({
      query: ({ user_id, body }) => ({
        url: `${END_POINTS.rejectUser}/${user_id}`,
        method: "POST",
        body,
      }),
    }),
    getAllListedPropertyByUser: builder.query<
      CommonResponseType & { data: ListedPropertiesByUser },
      PropertByUserParams
    >({
      query: ({ user_id, investmentType, page, limit }) => ({
        url:
          `${END_POINTS.getAllListedPropertyByUser}/${user_id}` +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          investmentType,
        method: "GET",
      }),
    }),
    getAllPurchasedPropertyByUser: builder.query<
      CommonResponseType & { data: PurchasedPropertiesByUser },
      PropertByUserParams
    >({
      query: ({ user_id, investmentType, page, limit }) => ({
        url:
          `${END_POINTS.getAllPurchasedPropertyByUser}/${user_id}` +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          investmentType,
        method: "GET",
      }),
    }),
    editUserById: builder.mutation<
      CommonResponseType & { data: GetUserById[] },
      { user_id: string; body: EditUserById }
    >({
      query: ({ user_id, body }) => ({
        url: `${END_POINTS.editUser}/${user_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteUserId: builder.query<
      CommonResponseType & { data: GetUserById[] },
      { user_id: string }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.deleteUserById}/${user_id}`,
        method: "DELETE",
      }),
    }),
    getRequestedProperties: builder.query<
      CommonResponseType & { data: GetRequestedPropertiesResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.getRequestedProperties +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getRequestedPropertyById: builder.query<
      CommonResponseType & { data: RequestedProperty[] },
      GetRequestedPropertyByIdParams
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.getRequestedPropertyById}/${property_id}`,
        method: "GET",
      }),
    }),
    deleteRequestedProperty: builder.query<
      CommonResponseType & { data: RequestedProperty[] },
      { property_id: string }
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.deleteRequestedProperty}/${property_id}`,
        method: "DELETE",
      }),
    }),
    approveProperty: builder.mutation<
      CommonResponseType & { data: ApprovedPropertyResponse },
      { property_id: string }
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.approveProperty}/${property_id}`,
        method: "POST",
      }),
    }),
    rejectProperty: builder.query<
      CommonResponseType & { data: ApprovedPropertyResponse },
      { property_id: string }
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.rejectProperty}/${property_id}`,
        method: "GET",
      }),
    }),
    getApprovedProperties: builder.query<
      CommonResponseType & { data: GetRequestedPropertiesResponse },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.getApprovedProperties +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    changePropertyStatus: builder.query<
      CommonResponseType & { data: GetUserStatusResponse },
      { property_id: string }
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.propertyStatus}/${property_id}/`,
        method: "GET",
      }),
    }),
    getPropertyById: builder.query<
      CommonResponseType & { data: RequestedProperty[] },
      GetRequestedPropertyByIdParams
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.getApprovedPropertyById}/${property_id}`,
        method: "GET",
      }),
    }),
    updateProperty: builder.mutation<
      CommonResponseType & { data: RequestedProperty[] },
      { property_id: string | undefined; body: any }
    >({
      query: ({ body, property_id }) => ({
        url: `${END_POINTS.updateProperty}/${property_id}`,
        method: "PUT",
        body,
      }),
    }),
    deletePropertyId: builder.query<
      CommonResponseType & { data: RequestedProperty[] },
      { property_id: string }
    >({
      query: ({ property_id }) => ({
        url: `${END_POINTS.deleteProperty}/${property_id}`,
        method: "DELETE",
      }),
    }),
    postSetProfit: builder.mutation<
      CommonResponseType & { data: ApprovedPropertyResponse },
      {
        body: {
          propertyTax: number;
          electricity: number;
          water: number;
          propertyRent: number;
          propertyRentReceipt: string;
          propertyTaxReceipt: string;
          electricityBillReceipt: string;
          waterBillReceipt: string;
        };
        property_id: string | undefined;
      }
    >({
      query: ({ body, property_id }) => ({
        url: `${END_POINTS.setProfit}/${property_id}`,
        method: "POST",
        body,
      }),
    }),
    getProfitHistory: builder.query<
      CommonResponseType & { data: ProfitHistoryResponse },
      {}
    >({
      query: () => ({
        url: END_POINTS.getProfits,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useLazyGetAllUsersQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUserCSVQuery,
  useApproveUserByIdMutation,
  useRejectUserByIdMutation,
  useLazyChangeUserStatusQuery,
  useLazyGetAllListedPropertyByUserQuery,
  useLazyGetAllPurchasedPropertyByUserQuery,
  useLazyDeleteUserIdQuery,
  useEditUserByIdMutation,
  useLazyGetRequestedPropertiesQuery,
  useLazyGetRequestedPropertyByIdQuery,
  useLazyDeleteRequestedPropertyQuery,
  useLazyGetApprovedPropertiesQuery,
  useApprovePropertyMutation,
  useLazyRejectPropertyQuery,
  useLazyChangePropertyStatusQuery,
  useLazyGetPropertyByIdQuery,
  useUpdatePropertyMutation,
  useLazyDeletePropertyIdQuery,
  useLazyGetDashboardQuery,
  usePostSetProfitMutation,
  useLazyGetProfitHistoryQuery,
} = mainApi;
