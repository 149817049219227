import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { showError } from "../../constants";
import { DashboardData } from "../../types/General";
import { useLazyGetDashboardQuery } from "../../services/main";

const Analytics = () => {
  const [userFilter, setUserFilter] = useState<string>("monthly");
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<number[]>([]);
  const [transactionFilter, setTransactionFilter] = useState<string>("monthly");
  const [transactionName, setTransactionName] = useState<string[]>([]);
  const [transactionValues, setTransactionValues] = useState<number[]>([]);
  const [earningFilter, setEarningFilter] = useState<string>("monthly");
  const [earningNames, setEarningNames] = useState<string[]>([]);
  const [earningValues, setEarningValues] = useState<number[]>([]);
  const [investmentFilter, setInvestmentFilter] = useState<string>("monthly");
  const [investmentNames, setInvestmentNames] = useState<string[]>([]);
  const [investmentValues, setInvestmentValues] = useState<number[]>([]);

  const handleUserChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleTransactionChange = (event: SelectChangeEvent) => {
    setTransactionFilter(event.target.value as string);
  };
  const handleEarningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };
  const handleInvestmentChange = (event: SelectChangeEvent) => {
    setInvestmentFilter(event.target.value as string);
  };

  const graphCustomerReportData = {
    labels: userNames,
    datasets: [
      {
        label: "Customer Reports",
        data: userValues,
        borderColor: "#cdff00",
        backgroundColor: "#cdff00",
      },
    ],
  };
  const graphTransactionReportsData = {
    labels: transactionName,
    datasets: [
      {
        label: "Transactions report",
        data: transactionValues,
        borderColor: "#cdff00",
        backgroundColor: "#cdff00",
      },
    ],
  };
  const [dashboardData, setDashboardData] = useState<DashboardData>();
  const [getDashBoardData] = useLazyGetDashboardQuery();

  const getDashBoard = async () => {
    try {
      const response = await getDashBoardData({
        user: userFilter,
        transactions: transactionFilter,
        earning: "",
        investment: investmentFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setDashboardData(response?.data);
        setUserNames(Object.keys(response?.data?.userData));
        setUserValues(Object.values(response?.data?.userData));
        setTransactionName(Object.keys(response?.data?.transactionData));
        setTransactionValues(Object.values(response?.data?.transactionData));
        setInvestmentNames(Object.keys(response?.data?.investmentData));
        setInvestmentValues(Object.values(response?.data?.investmentData));
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getDashBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilter, transactionFilter, earningFilter, investmentFilter]);

  const graphEarningsData = {
    labels: earningNames,
    datasets: [
      {
        label: "Earnings",
        data: earningValues,
        borderColor: "#cdff00",
        backgroundColor: "#cdff00",
      },
    ],
  };
  const graphPropertyInvestmentsData = {
    labels: investmentNames,
    datasets: [
      {
        label: "Property Investments",
        data: investmentValues,
        borderColor: "#cdff00",
        backgroundColor: "#cdff00",
      },
    ],
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Reports and Analytics</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Customer Report
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleUserChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphCustomerReportData} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Transactions Report
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={transactionFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleTransactionChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphTransactionReportsData} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Earnings
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={earningFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleEarningChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphEarningsData} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="cards dashGraph_item">
              <h2>
                Property Investments
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={investmentFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleInvestmentChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphPropertyInvestmentsData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
