import { END_POINTS } from "../constants/url";
import {
  BlogByIdResponse,
  Blogtype,
  Faqs,
  QueryDetailResponse,
  QueryResponse,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllBlogsResponse = {
  blogsCount: number;
  blogs: Blogtype[];
};

type GetBlogsResponse = {
  title: string;
  description: string;
  blogImages: string;
  isDeleted: boolean;
  isBlocked: boolean;
  _id: string;
  createdAt: string;
};

type PostBlogBody = {
  title: string;
  description: string;
  blogImages: string;
};

type GetQueryResponse = {
  statusCode: number;
  message: string;
  data: QueryDetailResponse[];
};
export const blogsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBlogs: builder.query<
      CommonResponseType & { data: GetAllBlogsResponse },
      { page: number; limit: number; query: string }
    >({
      query: ({ page, limit, query }) => ({
        url:
          END_POINTS.getAllBlogs +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    postBlog: builder.mutation<
      CommonResponseType & { data: GetBlogsResponse },
      PostBlogBody
    >({
      query: (body) => ({
        url: `${END_POINTS.createBlog}`,
        method: "POST",
        body,
      }),
    }),
    changeBlogStatus: builder.query<
      CommonResponseType & { data: GetAllBlogsResponse },
      { blog_id: string }
    >({
      query: ({ blog_id }) => ({
        url: `${END_POINTS.blockUnblockBlog}/${blog_id}`,
        method: "GET",
      }),
    }),
    getBlogsById: builder.query< CommonResponseType & { data: BlogByIdResponse[] } , { blog_id: string }>({
      query: ({ blog_id }) => ({
        url: `${END_POINTS.getBlogById}/${blog_id}`,
        method: "GET",
      }),
    }),
    updateBlog: builder.mutation<
    CommonResponseType & { data: GetBlogsResponse },
    {body:PostBlogBody, blog_id:string}
  >({
    query: ({body,blog_id}) => ({
      url: `${END_POINTS.updateBlog}/${blog_id}`,
      method: "PUT",
      body,
    }),
  }),
    deleteBlogById: builder.query<
      CommonResponseType & { data: GetAllBlogsResponse },
      { blog_id: string }
    >({
      query: ({ blog_id }) => ({
        url: `${END_POINTS.deleteBlog}/${blog_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetAllBlogsQuery,
  usePostBlogMutation,
  useLazyChangeBlogStatusQuery,
  useLazyGetBlogsByIdQuery,
  useLazyDeleteBlogByIdQuery,
  useUpdateBlogMutation,
} = blogsApi;
