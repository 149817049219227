import { END_POINTS } from "../constants/url";
import { Faqs, teamData } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetCmsResponse = {
  statusCode: number;
  message: string;
  data: {
    _id: string;
    adminId: string;
    AboutUs: string;
    privacyPolicy: string;
    TermsandConditions: string;
    legal: string;
    email: string;
    phone: string;
    countryCode: string;
  }[];
};

type PostCmsResponse = {
  message: string;
  statusCode: number;
  data: {
    _id: string;
    adminId: string;
    AboutUs: string;
    privacyPolicy: string;
    TermsandConditions: string;
    legal: string;
    email: string;
    phone: string;
  }[];
};

type PostCmsBody = {
  email: string;
  phone: string;
  AboutUs: string;
  TermsandConditions: string;
  privacyPolicy: string;
  legal: string;
  countryCode: string;
};
type GetFaqResponse = {
  data: Faqs[];
  statusCode: number;
  message: string;
};
type FaqBody = {
  question: string;
  answer: string;
};

type PostAboutUs = {
  title?: string;
  description?: string;
  image?: string;
  storyDescription?: string;
  missionDescription?: string;
  team: teamData[];
};

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export type AboutUsRes = {
  team: {
    Designation: string;
    name: string;
    _id: string;
    imagePath: string;
  }[];
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.get_cms,
        method: "GET",
      }),
    }),
    postCms: builder.mutation<PostCmsResponse, { body: PostCmsBody }>({
      query: ({ body }) => ({
        url: END_POINTS.addCms,
        method: "POST",
        body,
      }),
    }),
    updateCms: builder.mutation<PostCmsResponse, { body: PostCmsBody }>({
      query: ({ body }) => ({
        url: END_POINTS.updateCms,
        method: "PUT",
        body,
      }),
    }),
    getFaq: builder.query<GetFaqResponse, {}>({
      query: () => ({
        url: END_POINTS.getFaq,
        method: "GET",
      }),
    }),
    addFaq: builder.mutation<GetFaqResponse, FaqBody>({
      query: (body) => ({
        url: END_POINTS.addFaq,
        method: "POST",
        body,
      }),
    }),
    deleteFaq: builder.query<GetFaqResponse, { faq_id: string }>({
      query: ({ faq_id }) => ({
        url: `${END_POINTS.deleteFaq}/${faq_id}`,
        method: "DELETE",
      }),
    }),
    postAboutUs: builder.mutation<PostCmsResponse, PostAboutUs>({
      query: (body) => ({
        url: END_POINTS.addAboutUs,
        method: "PUT",
        body,
      }),
    }),
    getAboutUs: builder.query<CommonResponseType & { data: AboutUsRes[] }, {}>({
      query: () => ({
        url: END_POINTS.getAboutUs,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetCmsQuery,
  usePostCmsMutation,
  useUpdateCmsMutation,
  useLazyGetFaqQuery,
  useAddFaqMutation,
  useLazyDeleteFaqQuery,
  usePostAboutUsMutation,
  useLazyGetAboutUsQuery,
} = cmsApi;
