import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import { Loader, showError } from "../../constants";

import MainContainer from "../../layout/MainContainer";
import { QueryDetailResponse } from "../../types/General";
import { useLazyGetTicketsByIdQuery } from "../../services/customerSupport";

const QueryDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;

  const [queryDetail, setQueryDetail] = useState<QueryDetailResponse>();

  const [getQueryDetailById, { isLoading }] = useLazyGetTicketsByIdQuery();

  const getQueryDetail = async (id: string) => {
    try {
      const response = await getQueryDetailById({
        query_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setQueryDetail(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getQueryDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Query Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/customer-support");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2">User Details</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Username</Typography>
                      <Typography component="p">
                        {queryDetail?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {queryDetail?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Contact No</Typography>
                      <Typography component="p">
                        {" "}
                        {queryDetail?.countryCode
                          ? (queryDetail?.countryCode.includes("+")
                              ? ""
                              : "+") + queryDetail?.countryCode
                          : null}{" "}
                        {queryDetail?.phone || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2">Query Details</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Issue Type</Typography>
                      <Typography component="p">
                        {queryDetail?.subject || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Query Status</Typography>
                      <Typography component="p">
                        {" "}
                        {queryDetail?.status || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">
                        {queryDetail?.message || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Revert</Typography>
                      <Typography component="p">
                        {queryDetail?.replies[0]?.message || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default QueryDetails;
