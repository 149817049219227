import React from "react";
import MainContainer from "../../layout/MainContainer";
import { Box, Button, Card, Grid, Typography } from "@mui/material";

const ManageEarnings = () => {
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Earnings</h1>
        </div>
        <Card className="cards">
          <Grid container spacing={2} className="view_box_list">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <Typography component="h2">Platform Earnings</Typography>
                <Typography component="p">USDT 1000</Typography>
              </Box>
              <Box className="">
                <Button size="large" type="submit" className="btn btn_primary">
                  Withdraw Earnings
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageEarnings;
