import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { TransactionsResponse } from "../../types/General";
import { useLazyGetAllTransactionsQuery } from "../../services/news";
import { showError } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import moment from "moment";

const ManageTransactions = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [copiedAddressIndex, setCopiedAddressIndex] = useState<number>(-1);
  const [fromAddressCopiedIndex, setFromAddressCopiedIndex] =
    useState<number>(-1);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [transactionsData, setTransactionsData] = useState<
    TransactionsResponse[]
  >([]);
  const [getTransactions, { isLoading }] = useLazyGetAllTransactionsQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const getTransactionsData = async () => {
    try {
      const response = await getTransactions({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setTransactionsData(response?.data?.transaction || []);
        setTotalCount(response?.data?.transactionCount);
      } else {
        setTransactionsData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getTransactionsData();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Transactions</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Tokens Bought </TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>From Address</TableCell>
                  <TableCell>To Address</TableCell>
                </TableRow>
              </TableHead>
              {transactionsData?.length ? (
                transactionsData?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>{item?.userName || "-"}</TableCell>
                        <TableCell> {item?.email || "-"}</TableCell>
                        <TableCell align="center">
                          {item?.boughtTokens || "-"}
                        </TableCell>
                        <TableCell>
                          {moment(item?.createdAt).format("DD/MM/YYY") || "-"}
                        </TableCell>

                        <TableCell>
                          {item?.propOwnerWallet?.length > 13
                            ? item?.propOwnerWallet?.slice(0, 6) +
                              "....." +
                              item?.propOwnerWallet?.slice(-5)
                            : "-"}
                          {item?.propOwnerWallet !== "" ? (
                            <Tooltip
                              title={
                                fromAddressCopiedIndex === i
                                  ? "Address Copied!"
                                  : "Copy Address"
                              }
                              arrow
                            >
                              <span>
                                <IconButton
                                  className="cpy_icn"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item?.propOwnerWallet || ""
                                    );
                                    setFromAddressCopiedIndex(i);
                                    setCopiedAddressIndex(-1);
                                  }}
                                  disabled={
                                    fromAddressCopiedIndex !== -1 &&
                                    fromAddressCopiedIndex === i
                                  }
                                  sx={{
                                    color: "#fff",
                                  }}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {item?.walletAddress?.length > 13
                            ? item?.walletAddress?.slice(0, 6) +
                              "....." +
                              item?.walletAddress?.slice(-5)
                            : "-"}
                          {item?.walletAddress !== "" ? (
                            <Tooltip
                              title={
                                copiedAddressIndex === i
                                  ? "Address Copied!"
                                  : "Copy Address"
                              }
                              arrow
                            >
                              <span>
                                <IconButton
                                  className="cpy_icn"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item?.walletAddress || ""
                                    );
                                    setCopiedAddressIndex(i);
                                    setFromAddressCopiedIndex(-1);
                                  }}
                                  disabled={
                                    copiedAddressIndex !== -1 &&
                                    copiedAddressIndex === i
                                  }
                                  sx={{
                                    color: "#fff",
                                  }}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "white" }}
                  >
                    No Blogs Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={transactionsData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
    </MainContainer>
  );
};

export default ManageTransactions;
