import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  InputAdornment,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "react-phone-input-2/lib/bootstrap.css";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { UploadMedia } from "../../utils/mediaUpload";
import { Loader, showError, showToast } from "../../constants";
import { isString, useFormik } from "formik";
import {
  useLazyGetSubadminByIdQuery,
  usePostSubadminMutation,
  useUpdateSubAdminMutation,
} from "../../services/subadmin";
import { GetSubadminResponse } from "../../types/General";

interface DataType {
  _id?: string;
  label: string;
  isEdit: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
}
const AddSubAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [image, setImage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [showPassword, setShowPassword] = useState(false);
  const [newData, setNewData] = useState<DataType[]>([] as any);
  const [subAdminData, setSubAdminData] = useState<GetSubadminResponse | null>(
    null
  );
  const [addSubAdmin, { isLoading }] = usePostSubadminMutation();
  const [subAdminById, subAdminByIdData] = useLazyGetSubadminByIdQuery();
  const [updateSubAdmin, updateSubadminData] = useUpdateSubAdminMutation();
  const [permissions, setPermissions] = useState<DataType[]>([
    {
      _id: "1",
      label: "Dashboard",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    {
      _id: "3",
      label: "Manage Users",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "4",
      label: "Manage Requests",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "5",
      label: "Manage Properties",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "6",
      label: "Reports and Analytics",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "7",
      label: "Manage CMS",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "8",
      label: "Manage Earnings",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "9",
      label: "Manage Transactions",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "10",
      label: "Manage Change Requests",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "11",
      label: "Manage Customer Support",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "12",
      label: "Manage Form Template",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "13",
      label: "Manage Blogs",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "14",
      label: "Manage News",
      isEdit: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
  ]);
  console.log(permissions, "permissions");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.label === key.label);
    if (type === "addEdit") {
      permissions[idx].isEdit = !key.isEdit;
      if (key.isEdit) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    } else if (type === "isview") {
      permissions[idx].isView = !key.isView;
    } else {
      permissions[idx].isDelete = !key.isDelete;
      if (key.isDelete) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    }
    setPermissions([...permissions]);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: subAdminData?.fullName || "",
      email: subAdminData?.email || "",
      phone: subAdminData?.phone || "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      fullName: Yup.string()
        .required("Full name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      password: id
        ? Yup.string().notRequired()
        : Yup.string().required("Password is required!"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if (!image) {
        setError(true);
        return;
      }
      permissions?.length > 0 &&
        permissions.map((element) => {
          const obj = {
            label: element?.label,
            isEdit: element.isEdit,
            isView: element.isView,
            isDelete: element.isDelete,
          };
          if (obj) {
            newData?.push(obj);
            setNewData([...newData]);
          }
        });
      let body = {
        email: formik.values.email,
        fullName: formik.values.fullName,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        profileImage: image,
        password: formik.values.password,
        permissions: newData.filter((element) => {
          return (
            element.isView === true ||
            element.isEdit === true ||
            element.isDelete === true
          );
        }),
      };
      console.log(body, "body");

      const update_body = {
        email: formik.values.email,
        fullName: formik.values.fullName,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        profileImage: image,
        permissions: newData.filter((element) => {
          return (
            element.isView === true ||
            element.isEdit === true ||
            element.isDelete === true
          );
        }),
      };

      if (id) {
        try {
          const response = await updateSubAdmin({
            sub_id: id,
            update_body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subadmin Updated Sucessfully");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addSubAdmin(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subadmin Created Sucessfully");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };
  const getSubAdminById = async (id: string) => {
    try {
      const response = await subAdminById({ sub_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setSubAdminData(response?.data);
        setPhoneCode(response?.data?.countryCode || "+91");
        setImage(response?.data?.profileImage || "");
        const modifiedArr2 = permissions.map((item) => {
          const foundItem = response?.data?.subAdminId?.permissions?.find(
            (i) => i?.label === item.label
          );
          if (foundItem) {
            return {
              ...item,
              isView: foundItem.isView,
              isEdit: foundItem.isEdit,
              isDelete: foundItem.isDelete,
            };
          }
          return item;
        });
        setPermissions(modifiedArr2);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getSubAdminById(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader
          isLoad={
            isLoading ||
            updateSubadminData?.isLoading ||
            subAdminByIdData?.isLoading
          }
        />
        <div className="main_loyout">
          <div className="dashboard">
            {id ? <h1>Edit Sub-Admin</h1> : <h1>Add Sub-Admin</h1>}
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Full Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="fullName"
                      variant="outlined"
                      fullWidth
                      placeholder="Full Name"
                      id="fullName"
                      onBlur={formik.handleBlur}
                      value={formik.values.fullName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.fullName && formik.errors.fullName
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      placeholder="Phone Number"
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {id ? null : (
                    <Grid item xs={4}>
                      <Typography className="custom_label">Password</Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder="Password"
                        fullWidth
                        name="password"
                        id="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className="eye_btn" position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TableContainer className="table_container">
                      <Box className="heading"></Box>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Module</TableCell>
                            <TableCell>Add/Edit</TableCell>
                            <TableCell>View</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions?.map((row, i) => (
                            <TableRow key={row?._id}>
                              <TableCell>{row.label}</TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{ color: "white" }}
                                  {...label}
                                  onChange={() =>
                                    onSelectChange(row, "addEdit")
                                  }
                                  checked={row?.isEdit || false}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{ color: "white" }}
                                  {...label}
                                  onChange={() => onSelectChange(row, "isview")}
                                  checked={row?.isView || false}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  sx={{ color: "white" }}
                                  {...label}
                                  onChange={() => onSelectChange(row, "isdel")}
                                  checked={row?.isDelete || false}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
