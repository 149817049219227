import { PropsWithoutRef, useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import useAuth from "../hooks/useAuth";
import { SIDEBAR_WIDTH } from "../constants";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const userData = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Users",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      label: "Manage Requests",
      icon: <WysiwygIcon />,
      key: "3",
      selected: "/manage-requests",
    },
    {
      label: "Manage Properties",
      icon: <ListAltIcon />,
      key: "4",
      selected: "/manage-properties",
    },
    {
      label: "Reports and Analytics",
      icon: <BarChartIcon />,
      key: "5",
      selected: "/analytics",
    },
    {
      label: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      key: "6",
      selected: "/cms",
    },

    {
      label: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "7",
      selected: "/manage-subAdmin",
    },

    {
      label: "Manage Earnings",
      icon: <AttachMoneyIcon />,
      key: "8",
      selected: "/manage-earnings",
    },
    {
      label: "Manage Transactions",
      icon: <CurrencyExchangeIcon />,
      key: "9",
      selected: "/manage-transactions",
    },
    {
      label: "Manage Change Requests",
      icon: <ChangeCircleIcon />,
      key: "10",
      selected: "/change-requests",
    },
    {
      label: "Manage Customer Support",
      icon: <SupportAgentIcon />,
      key: "11",
      selected: "/customer-support",
    },
    {
      label: "Manage Form Template",
      icon: <EditNoteIcon />,
      key: "12",
      selected: "/manage-form-template",
    },
    {
      label: "Manage Blogs",
      icon: <DriveFileRenameOutlineIcon />,
      key: "13",
      selected: "/manage-blogs",
    },
    {
      label: "Manage News",
      icon: <NewspaperIcon />,
      key: "14",
      selected: "/manage-news",
    },
    {
      label: "Manage Report",
      icon: <NewspaperIcon />,
      key: "14",
      selected: "/manage-report",
    },
  ];
  const [list, setList] = useState<any>(
    userData?.role === "ADMIN" ? items : userData?.permissions
  );
  console.log(userData?.permissions, "permissions");

  useEffect(() => {
    let arr = [];
    if (userData?.role === "ADMIN") {
      setList(items);
    } else if (userData?.permissions?.length) {
      const permission = userData?.permissions;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [userData]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure style={{ width: "180px", height: "60px" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.label} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.label} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
    </Box>
  );
}
