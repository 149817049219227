import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../../components/SearchBar";
import {
  AllRequestedProperties,
  ListedPropertiesByUserId,
} from "../../../types/General";
import moment from "moment";
import { Pagination } from "../../../components";

type ListedPropertiesType = {
  value2: number;
  listedProperties: ListedPropertiesByUserId[];
  page: number;
  onPageChange: (newPage: number) => void;
  totalPages: number;
};
const UserListedProperties = ({
  page,
  onPageChange,
  totalPages,
  value2,
  listedProperties,
}: ListedPropertiesType) => {
  function createData(
    title: string,
    location: string,
    propertyType: string,
    totalAmount: string,
    onSale: string
  ) {
    return {
      title,
      location,
      propertyType,
      totalAmount,
      onSale,
    };
  }

  function getDaySuffix(day: number) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  return (
    <>
      <Box className="cards_header">{/* <SearchBar /> */}</Box>
      {value2 === 0 ? (
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell> Title</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Onsale</TableCell>
              </TableRow>
            </TableHead>
            {listedProperties?.length ? (
              listedProperties?.map((item, i) => {
                return (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        {" "}
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{item?.propertyTitle || "-"}</TableCell>
                      <TableCell>{item?.propertyAddress || "-"}</TableCell>
                      <TableCell>{item?.propertyType || "-"}</TableCell>
                      <TableCell>{item?.propertySalePrice || "-"}</TableCell>
                      <TableCell>
                        {" "}
                        {item?.isOnSaleProperty ? "Yes" : "No" || "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })
            ) : (
              <TableBody>
                <TableCell align="center" colSpan={10} sx={{ color: "white" }}>
                  No Properties Found
                </TableCell>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell> Title</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Profit Expected</TableCell>
                <TableCell>Date of Profit Distribution</TableCell>

                <TableCell>On Sale</TableCell>
              </TableRow>
            </TableHead>
            {listedProperties?.length ? (
              listedProperties?.map((item, i) => {
                return (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>{item?.propertyTitle || "-"}</TableCell>
                      <TableCell>{item?.propertyAddress || "-"}</TableCell>
                      <TableCell>{item?.propertyType || "-"}</TableCell>
                      <TableCell>{item?.expectedProfit || "-"}</TableCell>
                      <TableCell>
                        {item?.profitDistributionDate || ""}
                        {getDaySuffix(Number(item?.profitDistributionDate))} of
                        every month
                      </TableCell>

                      <TableCell>
                        {item?.isOnSaleProperty ? "Yes" : "No" || "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })
            ) : (
              <TableBody>
                <TableCell align="center" colSpan={10} sx={{ color: "white" }}>
                  No Properties Found
                </TableCell>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
      <Pagination
        module={listedProperties}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </>
  );
};
export default UserListedProperties;
