import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { log } from "console";

interface CustomSelectProps {
  label: string;
  value: string;
  // options: { value: string | number; label: string }[];
  handleChange: (event: any) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  handleChange,
  value,
}) => {
  const options = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  return (
    <Grid item xs={4}>
      <Typography className="custom_label">{label}</Typography>
      <FormControl fullWidth>
        <Select
          className="select_div"
          onChange={(event) => handleChange(event)}
          value={value}
          inputProps={{
            "aria-label": "Without label",
          }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default CustomSelect;
