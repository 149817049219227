import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useLazyChangeUserStatusQuery,
  useLazyDeleteUserIdQuery,
  useLazyGetAllUsersQuery,
  useLazyGetUserCSVQuery,
} from "../../services/main";
import { AllUser } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { Pagination, WarnModal } from "../../components";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageUsers = () => {
  const userData = useAuth();
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [allUsers, setAllUsers] = useState<AllUser[]>([]);
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [getAllUsersList, { isLoading }] = useLazyGetAllUsersQuery();
  const [getUserCsvMethod] = useLazyGetUserCSVQuery();
  const [deleteUserById] = useLazyDeleteUserIdQuery();
  const [userStatus] = useLazyChangeUserStatusQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const checkPermission = () => {
    const permission = userData?.permissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Users"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const getUsers = async () => {
    try {
      const response = await getAllUsersList({
        status: value === 0 ? "APPROVED" : value === 1 ? "PENDING" : "REJECTED",
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllUsers(response?.data?.user || []);
        setTotalCount(response?.data?.userCount);
      } else {
        setAllUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteUserById({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        const res = await getAllUsersList({
          status:
            value === 0 ? "APPROVED" : value === 1 ? "PENDING" : "REJECTED",
          page: page,
          limit: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setAllUsers(res?.data?.user || []);
        } else {
          setAllUsers([]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string) => {
    try {
      const response = await userStatus({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        const res = await getAllUsersList({
          status:
            value === 0 ? "APPROVED" : value === 1 ? "PENDING" : "REJECTED",
          page: page,
          limit: 10,
          query: searchTerm,
        }).unwrap();
        if (res?.statusCode === 200) {
          setAllUsers(res?.data?.user || []);
        } else {
          setAllUsers([]);
        }
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers();
  }, [debouncedSearchTerm, page, value]);

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage User</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Approved" {...a11yProps(0)} />
              <Tab label="Pending" {...a11yProps(1)} />
              <Tab label="Rejected" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right">
                  <Button className="btn btn_primary" onClick={handleExportCsv}>
                    <FileDownloadIcon /> Export CSV
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Username</TableCell>
                      <TableCell align="center">Contact Number</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Account Status</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {allUsers?.length ? (
                    allUsers?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow key={item?._id}>
                            <TableCell align="center">
                              {" "}
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell align="center">
                              {`${item?.firstName} ${item?.lastName} ` || "-"}
                            </TableCell>

                            <TableCell align="center">
                              {item?.countryCode
                                ? (item?.countryCode.includes("+") ? "" : "+") +
                                  item?.countryCode
                                : null}{" "}
                              {item?.phone || "-"}
                            </TableCell>
                            <TableCell align="center">
                              {item?.email || "-"}
                            </TableCell>

                            <TableCell align="center">
                              <Switch
                                {...label}
                                size="small"
                                checked={!item?.isBlocked}
                                onChange={() => updateStatus(item?._id)}
                              />
                            </TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manageusers/userdetails/${item?._id}`,
                                      { state: { userStatus: "APPROVED" } }
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {hidePermission?.isEdit ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manageusers/usersform/${item?._id}`
                                      )
                                    }
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                ) : null}
                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "white" }}
                      >
                        No User Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right"></Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Username</TableCell>
                      <TableCell align="center">Contact Number</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {allUsers?.length ? (
                    allUsers?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow key={item?._id}>
                            <TableCell align="center">
                              {" "}
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell align="center">
                              {`${item?.firstName} ${item?.lastName} ` || "-"}
                            </TableCell>

                            <TableCell align="center">
                              {item?.countryCode
                                ? (item?.countryCode.includes("+") ? "" : "+") +
                                  item?.countryCode
                                : null}{" "}
                              {item?.phone || "-"}
                            </TableCell>
                            <TableCell align="center">
                              {item?.email || "-"}
                            </TableCell>

                            {/* <TableCell align="center">
                              {item?.userStatus || ""}
                            </TableCell> */}
                            <TableCell>
                              <Box className="table_actions">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manageusers/userdetails/${item?._id}`,
                                      { state: { userStatus: "PENDING" } }
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {hidePermission?.isEdit ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manageusers/usersform/${item?._id}`
                                      )
                                    }
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                ) : null}
                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "white" }}
                      >
                        No User Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Box className="cards_header_right"></Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Username</TableCell>
                      <TableCell align="center">Contact Number</TableCell>
                      <TableCell align="center">Email</TableCell>
                      {/* <TableCell align="center">User Status</TableCell> */}
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {allUsers?.length ? (
                    allUsers?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow key={item?._id}>
                            <TableCell align="center">
                              {" "}
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell align="center">
                              {`${item?.firstName} ${item?.lastName} ` || "-"}
                            </TableCell>

                            <TableCell align="center">
                              {item?.countryCode
                                ? (item?.countryCode.includes("+") ? "" : "+") +
                                  item?.countryCode
                                : null}{" "}
                              {item?.phone || "-"}
                            </TableCell>
                            <TableCell align="center">
                              {item?.email || "-"}
                            </TableCell>

                            {/* <TableCell align="center">
                              {item?.userStatus || ""}
                            </TableCell> */}
                            <TableCell>
                              <Box className="table_actions">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manageusers/userdetails/${item?._id}`,
                                      { state: { userStatus: "REJECTED" } }
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>

                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedId(item?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "white" }}
                      >
                        No User Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
        <Pagination
          module={allUsers}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="user"
      />
    </MainContainer>
  );
};

export default ManageUsers;
