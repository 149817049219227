import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Card,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const ManageFormTemplate = () => {
  const [interiorFeatures, setInteriorFeatures] = useState<string[]>([]);
  const [fieldNames, setFieldNames] = useState("");
  const [appliances, setAppliances] = useState<string[]>([]);
  const [applianceFields, setApplianceFields] = useState("");

  const addInteriorFeatures = () => {
    if (fieldNames !== "") {
      interiorFeatures.push(fieldNames);
    }
    setInteriorFeatures([...interiorFeatures]);
    setFieldNames("");
  };

  const deleteInteriorFeatures = (index: number) => {
    let arr1 = [];
    arr1.push(
      ...interiorFeatures.slice(0, index),
      ...interiorFeatures.slice(index + 1)
    );
    setInteriorFeatures([...arr1]);
  };
  const addAppliances = () => {
    if (applianceFields !== "") {
      appliances.push(applianceFields);
    }
    setAppliances([...appliances]);
    setApplianceFields("");
  };

  const deleteAppliances = (index: number) => {
    let arr1 = [];
    arr1.push(...appliances.slice(0, index), ...appliances.slice(index + 1));
    setAppliances([...arr1]);
  };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Form Template</h1>
        </div>
        <Card className="cards">
          <Grid container spacing={2} className="view_box_list">
            <Grid item xs={12}>
              <Box>
                <Typography
                  component="h2"
                  sx={{
                    fontSize: "20px",
                    color: "#cdff00",
                    fontWeight: 700,
                  }}
                >
                  Interior Features
                </Typography>
              </Box>
            </Grid>
            <Grid item sx={{ marginBottom: "24px" }} xs={4}>
              <Typography className="custom_label">Field Name</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="title"
                  variant="outlined"
                  value={fieldNames}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                      // formik.handleChange(null);
                    } else {
                      setFieldNames(val.target.value);
                    }
                  }}
                  fullWidth
                  placeholder="Field Name"
                />
                <IconButton onClick={addInteriorFeatures}>
                  <AddCircleIcon sx={{ color: "#cdff00" }} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} xs={12}>
                {interiorFeatures?.map((item, i) => {
                  return (
                    <Grid item xs={4} key={i}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          className="disabled_text"
                          value={item}
                          fullWidth
                          disabled
                        />
                        <IconButton onClick={() => deleteInteriorFeatures(i)}>
                          <DeleteIcon sx={{ color: "#cdff00" }} />
                        </IconButton>
                        {/* <IconButton
                      // onClick={handleAddInteriorFeatures}
                      >
                        <ModeEditIcon sx={{ color: "#cdff00" }} />
                      </IconButton> */}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography
                  component="h2"
                  sx={{
                    fontSize: "20px",
                    color: "#cdff00",
                    fontWeight: 700,
                  }}
                >
                  Appliances
                </Typography>
              </Box>
            </Grid>
            <Grid item sx={{ marginBottom: "24px" }} xs={4}>
              <Typography className="custom_label">Field Name</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="title"
                  variant="outlined"
                  value={applianceFields}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                      // formik.handleChange(null);
                    } else {
                      setApplianceFields(val.target.value);
                    }
                  }}
                  fullWidth
                  placeholder="Field Name"
                />
                <IconButton onClick={addAppliances}>
                  <AddCircleIcon sx={{ color: "#cdff00" }} />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} xs={12}>
                {appliances?.map((item, i) => {
                  return (
                    <Grid item xs={4} key={i}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          className="disabled_text"
                          value={item}
                          fullWidth
                          disabled
                        />
                        <IconButton onClick={() => deleteAppliances(i)}>
                          <DeleteIcon sx={{ color: "#cdff00" }} />
                        </IconButton>
                        {/* <IconButton
                      // onClick={handleAddInteriorFeatures}
                      >
                        <ModeEditIcon sx={{ color: "#cdff00" }} />
                      </IconButton> */}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageFormTemplate;
