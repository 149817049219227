import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Input,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";
import { ChangeEvent, useEffect, useState } from "react";

import { Loader, showError, showToast } from "../../constants";

import Web3 from "web3";
import {
  ABI,
  ContractAddress,
  USDTAddress,
  USDTAbi,
} from "../../utils/configuration";
import { useFormik } from "formik";
import { isNumber } from "../../utils/validations";
import {
  useLazyGetProfitHistoryQuery,
  usePostSetProfitMutation,
} from "../../services/main";
import { ProfitHistoryType } from "../../types/General";
import { Pagination } from "../../components";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { UploadMedia } from "../../utils/mediaUpload";

const SetProfit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state, "state");

  const { id } = useParams();
  const [error, setError] = useState(false);
  const [propertyRentImage, setPropertyRentImage] = useState<string>("");
  const [electricityBillImage, setElectricityBillImage] = useState<string>("");
  const [waterBillImage, setWaterBillImage] = useState<string>("");
  const [propertyTaxImage, setPropertyTaxImage] = useState<string>("");

  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<any>();
  const [usdt, setUsdt] = useState<any>();
  const [profitHistoryData, setProfitHistoryData] = useState<
    ProfitHistoryType[]
  >([]);

  const [getProfitHistory, getProfitHistoryData] =
    useLazyGetProfitHistoryQuery();

  const [postSetProfit, { isLoading }] = usePostSetProfitMutation();
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const getProfit = async () => {
    try {
      const response = await getProfitHistory({
        page: page,
        limit: 10,
        // query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setProfitHistoryData(response?.data?.profit || []);
        setTotalCount(response?.data?.profitCount);
      } else {
        setProfitHistoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getProfit();
  }, [page]);

  useEffect(() => {
    const init = async () => {
      if (window.ethereum !== "undefined") {
        try {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const web3 = new Web3(window.ethereum);
          const contractInstance = new web3.eth.Contract(ABI, ContractAddress);
          setContract(contractInstance);
          const USDTInstance = new web3.eth.Contract(USDTAbi, USDTAddress);
          setUsdt(USDTInstance);
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const account = accounts[0];
          // setToStorage(STORAGE_KEYS.walletAddress, account || null);
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        console.error("Please install MetaMask or any other Web3 provider.");
      }
    };
    init();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      propertyTax: "",
      electricity: "",
      water: "",
      collectedRent: "",
    },
    validationSchema: Yup.object({
      propertyTax: Yup.string().required("This field is required!"),
      electricity: Yup.string().required("This field is required!"),
      collectedRent: Yup.string().required("This field is required!"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const body = {
        propertyTax: parseInt(formik.values.propertyTax),
        electricity: parseInt(formik.values.electricity),
        water: parseInt(formik.values.water),
        propertyRent: parseInt(formik.values.collectedRent),
        propertyRentReceipt: propertyRentImage,
        propertyTaxReceipt: propertyTaxImage,
        electricityBillReceipt: electricityBillImage,
        waterBillReceipt: waterBillImage,
      };
      if (!propertyRentImage || !propertyTaxImage || !electricityBillImage) {
        setError(true);
        return;
      }
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const account = accounts[0];
        setLoading(true);

        const usdtApproval = await usdt.methods
          .approve(ContractAddress, parseInt(formik.values.collectedRent)) //contract add , no. of tokens
          .send({ from: account });
        if (usdtApproval) {
          console.log("usdtApproval: ", usdtApproval);
          console.log(state?.propertyId, "propertyId");
          const gas = await contract.methods
            .setProfit(parseInt(formik.values.collectedRent), state?.propertyId)
            .estimateGas({ from: account });
          console.log("gas: ", gas);
          console.log(
            formik.values.collectedRent,
            state?.propertyId,
            "state?.propertyId"
          );
          let setProfit = await contract.methods
            .setProfit(parseInt(formik.values.collectedRent), state?.propertyId)
            .send({ from: account, gas: gas });
          console.log("setProfit: ", setProfit);
          if (setProfit) {
            const response = await postSetProfit({
              body: body,
              property_id: id,
            }).unwrap();
            if (response?.statusCode === 200) {
              showToast("Profit Set Successfully");
              setLoading(false);
            }
          }
        }
      } catch (error: any) {
        console.log(error, "error");
        showError(error?.data?.message || "");
        setLoading(false);
      }
    },
  });

  const handleImageUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    try {
      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        if (key === "propertyRentImage") {
          setPropertyRentImage(res?.data);
        } else if (key === "electricityBillImage") {
          setElectricityBillImage(res?.data);
        } else if (key === "waterBillImage") {
          setWaterBillImage(res?.data);
        } else {
          setPropertyTaxImage(res?.data);
        }
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MainContainer>
        <Loader
          isLoad={isLoading || getProfitHistoryData?.isLoading || loading}
        />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Set Profit Amount</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-properties");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Property Tax
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="propertyTax"
                      variant="outlined"
                      fullWidth
                      placeholder="Property Tax"
                      className="text_field"
                      id="propertyTax"
                      onBlur={formik.handleBlur}
                      value={formik.values.propertyTax}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.propertyTax && formik.errors.propertyTax
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Electricity
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="electricity"
                      variant="outlined"
                      fullWidth
                      placeholder="Electricity"
                      className="text_field"
                      id="electricity"
                      onBlur={formik.handleBlur}
                      value={formik.values.electricity}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.electricity && formik.errors.electricity
                      }
                    ></TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">Water</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="water"
                      variant="outlined"
                      fullWidth
                      placeholder="Water"
                      className="text_field"
                      id="water"
                      onBlur={formik.handleBlur}
                      value={formik.values.water}
                      onChange={formik.handleChange}
                      helperText={formik.touched.water && formik.errors.water}
                    ></TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Collected Rent
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="collectedRent"
                      variant="outlined"
                      fullWidth
                      placeholder="Collected Rent"
                      className="text_field"
                      id="collectedRent"
                      onBlur={formik.handleBlur}
                      value={formik.values.collectedRent}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.collectedRent &&
                        formik.errors.collectedRent
                      }
                    ></TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        color: "#cdff00",
                        fontSize: "18px ",
                        fontWeight: "600",
                      }}
                      component="h2"
                    >
                      Upload Receipts
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Property Rent
                    </Typography>
                    <div className="upload_document w_100">
                      {propertyRentImage ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setPropertyRentImage("");
                              }}
                            />
                          </div>

                          <figure>
                            <img src={propertyRentImage} alt="" />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-front">
                          <div className="inner">
                            <span>
                              <AddIcon />
                            </span>
                            <Input
                              id="icon-button-file-front"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleImageUpload(e, "propertyRentImage")}
                            />
                          </div>

                          {error && !propertyRentImage ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null}
                        </label>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Electricity
                    </Typography>
                    <div className="upload_document w_100">
                      {electricityBillImage ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setElectricityBillImage("");
                              }}
                            />
                          </div>

                          <figure>
                            <img src={electricityBillImage} alt="" />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-back">
                          <div className="inner">
                            <span>
                              <AddIcon />
                            </span>
                            <Input
                              id="icon-button-file-back"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleImageUpload(e, "electricityBillImage")}
                            />
                          </div>

                          {error && !electricityBillImage ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null}
                        </label>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Water</Typography>
                    <div className="upload_document w_100">
                      {waterBillImage ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setWaterBillImage("");
                              }}
                            />
                          </div>

                          <figure>
                            <img src={waterBillImage} alt="" />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-water">
                          <div className="inner">
                            <span>
                              <AddIcon />
                            </span>
                            <Input
                              id="icon-button-file-water"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleImageUpload(e, "waterBillImage")}
                            />
                          </div>

                          {/* {error && !waterBillImage ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null} */}
                        </label>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Property Tax
                    </Typography>
                    <div className="upload_document w_100">
                      {propertyTaxImage ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setPropertyTaxImage("");
                              }}
                            />
                          </div>

                          <figure>
                            <img src={propertyTaxImage} alt="" />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-tax">
                          <div className="inner">
                            <span>
                              <AddIcon />
                            </span>
                            <Input
                              id="icon-button-file-tax"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleImageUpload(e, "propertyTaxImage")}
                            />
                          </div>

                          {error && !propertyTaxImage ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null}
                        </label>
                      )}
                    </div>
                  </Grid>
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
          <Card sx={{ mt: 4 }} className="cards">
            <Box className="custom_tabs">
              <Typography variant="h6" sx={{ color: "#fff" }}>
                Profit History
              </Typography>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell> Date</TableCell>
                      <TableCell>Profit</TableCell>
                      <TableCell>PropertyTax</TableCell>
                      <TableCell>Electricity</TableCell>
                      <TableCell>Water</TableCell>
                    </TableRow>
                  </TableHead>
                  {profitHistoryData?.length ? (
                    profitHistoryData?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {" "}
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              {moment(item?.createdAt).format("DD/MM/YYYY") ||
                                "-"}
                            </TableCell>
                            <TableCell>
                              {`USDT ${item?.propertyRent}` || "-"}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {`USDT ${item?.propertyTax}` || "-"}
                            </TableCell>
                            <TableCell>
                              {`USDT ${item?.electricity}` || "-"}
                            </TableCell>
                            <TableCell>
                              {`USDT ${item?.water}` || "-"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "white" }}
                      >
                        No data found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Card>
          <Pagination
            module={profitHistoryData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        </div>
      </MainContainer>
    </>
  );
};

export default SetProfit;
