import React from "react";
import "./App.scss";
import Routing from "./Routes";

function App() {
  return <Routing />;
}

export default App;

// import Routing from "./Routes";
// import "./App.scss";
// import { useEffect, useState } from "react";
// import Web3 from "web3";
// import { ABI, ContractAddress } from "./utils/configuration";
// import { showError } from "./constants";
// function App() {
//   const [contract, setContract] = useState<any>();

//   const handleApprove = async () => {
//     console.log("jjj");
//     try {
//       const accounts = await window.ethereum.request({
//         method: "eth_requestAccounts",
//       });
//       const account = accounts[0];
//       console.log(account, "8888888");
//       if (contract) {
//         console.log("contract: ", contract);
//         const gas = await contract.methods
//           .approveForMinting(
//             "0x9c7260a31c173a4d2b8fa4ada924f315e7d29a64",
//             100,
//             12,
//             5
//           )
//           .estimateGas({ from: "0xd0abc9ff910330e587f4b974b0527e49a3f626f5" });
//         console.log("gas: ", gas);
//         let approveForMinting = await contract.methods
//           .approveForMinting(
//             "0x9c7260a31c173a4d2b8fa4ada924f315e7d29a64",
//             100,
//             12,
//             5
//           )
//           .send({
//             from: "0xd0abc9ff910330e587f4b974b0527e49a3f626f5",
//             gas: gas,
//           });
//         console.log("approveForMinting: ", approveForMinting);
//       }
//     } catch (error: any) {
//       console.log(error, "error");
//       showError(error?.message || "");
//     }
//   };
//   useEffect(() => {
//     const init = async () => {
//       if (window.ethereum !== "undefined") {
//         try {
//           await window.ethereum.request({ method: "eth_requestAccounts" });
//           const web3 = new Web3(window.ethereum);
//           const contractInstance = new web3.eth.Contract(ABI, ContractAddress);
//           setContract(contractInstance);
//           const accounts = await window.ethereum.request({
//             method: "eth_requestAccounts",
//           });
//           const account = accounts[0];
//           // setToStorage(STORAGE_KEYS.walletAddress, account || null);
//         } catch (error) {
//           console.error("Error:", error);
//         }
//       } else {
//         console.error("Please install MetaMask or any other Web3 provider.");
//       }
//     };
//     init();
//   }, []);
//   return (
//     <div className="App">
//       <h3 onClick={handleApprove}>Mint</h3>
//       {/* <Router />
//       <ToastContainer position="bottom-right" /> */}
//     </div>
//   );
// }
// export default App;
