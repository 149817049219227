import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import * as Yup from "yup";
import { isString, useFormik } from "formik";
import { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate, useParams } from "react-router-dom";

import MainContainer from "../../layout/MainContainer";
import { UploadMedia } from "../../utils/mediaUpload";
import { Loader, showError, showToast } from "../../constants";

import { NewsByIdResponse } from "../../types/General";
import {
  useLazyGetNewsByIdQuery,
  usePostNewsMutation,
  useUpdateNewsMutation,
} from "../../services/news";

const AddNews = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [image, setImage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [getNewsById, getNewsByIdData] = useLazyGetNewsByIdQuery();
  const [addNews, { isLoading }] = usePostNewsMutation();
  const [updateNews, getUpdateNewsdata] = useUpdateNewsMutation();

  const [newsData, setNewsData] = useState<NewsByIdResponse>();

  const getNewsDetail = async (id: string) => {
    try {
      const response = await getNewsById({
        news_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setNewsData(response?.data[0]);
        setImage(response?.data[0]?.newsImage || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: newsData?.title || "",
      description: newsData?.description || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is required")
        .min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("Description  is required")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      console.log("jhjhj");
      const body = {
        title: formik.values.title,
        description: formik.values.description,
        newsImage: image,
      };
      if (!image) {
        setError(true);
        return;
      }
      if (id) {
        try {
          console.log("edit");
          const response = await updateNews({
            body,
            news_id: `${id}`,
          }).unwrap();
          showToast(response?.message || "News Updated Successfully");
          navigate("/manage-news");
        } catch (error: any) {
          console.log(error);
        }
      } else {
        try {
          console.log("add");
          const response = await addNews(body).unwrap();
          showToast(response?.message || "News Added Successfully");
          navigate("/manage-news");
        } catch (error: any) {
          console.log(error);
        }
      }
    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };
  useEffect(() => {
    if (id) {
      getNewsDetail(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader
          isLoad={
            isLoading ||
            getUpdateNewsdata?.isLoading ||
            getNewsByIdData?.isLoading
          }
        />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit News" : "Add News"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-news");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview2">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          sx={{ color: "#cdff00" }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_dv">
                            <AddIcon className="add_icn_btn" />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Title</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      id="title"
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Description"
                      fullWidth
                      multiline
                      minRows={1.2}
                      maxRows={4}
                      name="description"
                      variant="outlined"
                      id="description"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    ></TextField>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNews;
