import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import ChangePassword from "./auth/changePassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/edit";
import ManageProperties from "./manageProperties";
import EditProperties from "./manageProperties/edit";
import ManageRequests from "./manageRequests";
import RequestDetails from "./manageRequests/requestDetails";
import PropertyDetails from "./manageProperties/details";
import ManageEarnings from "./manageEarnings";
import ManageTransactions from "./manageTransactions";
import ManageChangeRequests from "./changeRequests";
import TransactionDetails from "./manageTransactions/details";
import ManageFormTemplate from "./formTemplate";
import QueryDetails from "./customerSupport/detail";
import SetProfit from "./manageProperties/setProfit";
import ManageBlogs from "./manageBlogs";
import AddBlog from "./manageBlogs/add";
import BlogDetails from "./manageBlogs/details";
import ManageNews from "./manageNews";
import NewsDetails from "./manageNews/details";
import AddNews from "./manageNews/add";
import AboutUs from "./abouUs";
import ManageReport from "./manageReport";
import ReportDetail from "./manageReport/reportDetails";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  ChangePassword,
  Profile,
  ManageCms,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageProperties,
  EditProperties,
  ManageRequests,
  RequestDetails,
  PropertyDetails,
  ManageEarnings,
  ManageTransactions,
  ManageChangeRequests,
  TransactionDetails,
  ManageFormTemplate,
  QueryDetails,
  SetProfit,
  ManageBlogs,
  AddBlog,
  BlogDetails,
  ManageNews,
  NewsDetails,
  AddNews,
  AboutUs,
  ManageReport,
  ReportDetail,
};
