import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomSelect } from "../../components";
import { Loader, showError, showToast } from "../../constants";
import { RequestedProperty } from "../../types/General";
import {
  useLazyGetPropertyByIdQuery,
  useUpdatePropertyMutation,
} from "../../services/main";

import { isString, useFormik } from "formik";
import { isNumber } from "../../utils/validations";
import moment from "moment";

const EditProperties = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();

  const [propertyType, setPropertyType] = useState<string>("Private");
  const [investmentType, setInvestmentType] =
    useState<string>("Profit Sharing");
  const [sink, setSink] = useState<string>("Yes");
  const [pantry, setPantry] = useState<string>("Yes");
  const [sittingArea, setSittingArea] = useState<string>("Yes");
  const [bar, setBar] = useState<string>("Yes");
  const [shower, setShower] = useState<string>("Yes");
  const [walkInClosets, setWalkinClosets] = useState<string>("Yes");
  const [dishwasher, setDishwasher] = useState<string>("Yes");
  const [dryer, setdryer] = useState<string>("Yes");
  const [refrigerator, setRefrigerator] = useState<string>("Yes");
  const [washer, setwasher] = useState<string>("Yes");
  const [microwave, setMicrowave] = useState<string>("Yes");
  const [oven, setOven] = useState<string>("Yes");
  const [range, setRange] = useState<string>("Yes");
  const [iceMaker, setIceMaker] = useState<string>("Yes");
  const [parkingFeature, setParkingFeature] = useState<string[]>([]);
  const [exteriorFeature, setExteriorFeature] = useState<string[]>([]);
  const [getPropertyDeatilById, { isLoading }] = useLazyGetPropertyByIdQuery();
  const [updateProperty] = useUpdatePropertyMutation();
  const [propertyData, setPropertyData] = useState<RequestedProperty>();
  const [parking, setParking] = useState("");
  const [exterior, setExterior] = useState("");
  const [profitSharingDate, setProfitSharingDate] = useState("");
  const [error, setError] = useState(false);

  const getPropertyDetail = async (id: string) => {
    try {
      const response = await getPropertyDeatilById({
        property_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setPropertyData(response?.data[0]);
        setExteriorFeature(response?.data[0]?.exteriorLotFeatures);
        setParkingFeature(response?.data[0]?.parkingFeatures);
        setPropertyType(response?.data[0]?.propertyType);
        setInvestmentType(response?.data[0]?.investmentType);
        setSink(response?.data[0]?.dualSink);
        setPantry(response?.data[0]?.pantry);
        setSittingArea(response?.data[0]?.sittingArea);
        setBar(response?.data[0]?.Bar);
        setShower(response?.data[0]?.separateShower);
        setWalkinClosets(response?.data[0]?.walkInClosets);
        setDishwasher(response?.data[0]?.dishwasher);
        setdryer(response?.data[0]?.dryer);
        setRefrigerator(response?.data[0]?.refrigerator);
        setwasher(response?.data[0]?.washingMachine);
        setMicrowave(response?.data[0]?.microwave);
        setOven(response?.data[0]?.Oven);
        setRange(response?.data[0]?.Range);
        setIceMaker(response?.data[0]?.iceMaker);
        setProfitSharingDate(response?.data[0]?.profitDistributionDate);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  const handlePropertyType = (event: SelectChangeEvent) => {
    setPropertyType(event.target.value as string);
  };

  const handleExterior = () => {
    if (exterior) {
      setExteriorFeature((prevFeatures: any) => [...prevFeatures, exterior]);
    }
    setExterior("");
  };
  const handleParking = () => {
    if (parking) {
      setParkingFeature((prevFeatures: any) => [...prevFeatures, parking]);
    }
    setParking("");
  };
  const handleDeleteExteriorFeature = (index: number) => {
    setExteriorFeature((prevFeatures: any) =>
      prevFeatures.filter((_: any, i: any) => i !== index)
    );
  };
  const handleDeleteParkingFeature = (index: number) => {
    setParkingFeature((prevFeatures: any) =>
      prevFeatures.filter((_: any, i: any) => i !== index)
    );
  };

  useEffect(() => {
    if (id) {
      getPropertyDetail(id);
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: propertyData?.propertyTitle || "",
      location: propertyData?.propertyAddress || "",
      noOfTokens: propertyData?.totalTokens || "",
      noOfRooms: propertyData?.bedroomsCount || "",
      profitExpected: propertyData?.expectedProfit || "",
      description: propertyData?.propertyDescription || "",
      builtIn: propertyData?.BuiltInFeature || "",
      propertyValue: propertyData?.propertyPrice || "",
      bedroomMainLevel: propertyData?.bedroomMainLevel || "",
      diningArea: propertyData?.diningArea || "",
      yearBuilt: propertyData?.YearBuilt
        ? moment(propertyData?.YearBuilt).format("YYYY-MM-DD")
        : "",
      constructionMaterial: propertyData?.ContructionMeterial || "",
      flooring: propertyData?.Flooring || "",
      roof: propertyData?.Roof || "",
      architecturalStyle: propertyData?.ArchitecturalStyle || "",
      view: propertyData?.View || "",
      waterfrontFeatures: propertyData?.WaterFrontFeature || "",
      waterFront: propertyData?.WaterFront || "",
      accessibilityFeatures: propertyData?.AccessibilityFeature || "",
      taxes: propertyData?.Taxes || "",

      //ownership
      totalPropertyAmount: propertyData?.propertyPrice || "",
      milestone1: propertyData?.milestoneAmount1 || "",
      milestone2: propertyData?.milestoneAmount2 || "",
      milestone3: propertyData?.milestoneAmount3 || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field is required!"),
      description: Yup.string().required("This field is required!"),
      propertyValue: Yup.string().required("This field is required!"),
      location: Yup.string().required("This field is required!"),
      noOfTokens:
        state?.propertyType === "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),

      profitExpected:
        state?.propertyType === "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),

      totalPropertyAmount:
        state?.propertyType !== "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
      milestone1:
        state?.propertyType !== "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
      milestone2:
        state?.propertyType !== "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
      milestone3:
        state?.propertyType !== "Profit Sharing"
          ? Yup.string().required("This field is required!")
          : Yup.string().notRequired(),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      if (state?.propertyType === "Profit Sharing") {
        const body = {
          propertyTitle: formik.values.title,
          propertyDescription: formik.values.description,
          totalTokens: formik.values.noOfTokens,
          propertyType: propertyType,
          profitDistributionDate: profitSharingDate,
          investmentType: "Profit Sharing",
          expectedProfit: formik.values.profitExpected,
          propertyPrice: formik.values.propertyValue,
          propertyAddress: formik.values.location,

          //interior
          BuiltInFeature: formik.values.builtIn,
          diningArea: formik.values.diningArea,
          bedroomMainLevel: formik.values.bedroomMainLevel,

          dualSink: sink,
          pantry: pantry,
          sittingArea: sittingArea,
          Bar: bar,
          separateShower: shower,
          walkInClosets: walkInClosets,
          //appliances
          dishwasher: dishwasher,
          dryer: dryer,
          refrigerator: refrigerator,
          washingMachine: washer,
          microwave: microwave,
          Oven: oven,
          iceMaker: iceMaker,
          parkingFeatures: parkingFeature,
          exteriorLotFeatures: exteriorFeature,

          //construction
          YearBuilt: formik.values.yearBuilt,
          ContructionMeterial: formik.values.constructionMaterial,
          Flooring: formik.values.flooring,
          Roof: formik.values.roof,
          ArchitecturalStyle: formik.values.architecturalStyle,

          //other
          View: formik.values.view,
          WaterFront: formik.values.waterFront,
          WaterFrontFeature: formik.values.waterfrontFeatures,
          AccessibilityFeature: formik.values.accessibilityFeatures,
          Taxes: formik.values.taxes,
        };

        if (state?.propertyType === "Profit Sharing" && !profitSharingDate) {
          setError(true);
          return;
        }
        try {
          const response = await updateProperty({
            body: body,
            property_id: id,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Property Updated Successfully");
            navigate("/manage-properties/");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        //owernership body
        const body_ownership = {
          propertyTitle: formik.values.title,
          propertyDescription: formik.values.description,
          propertyAddress: formik.values.location,
          propertyPrice: Number(formik.values.totalPropertyAmount),
          milestoneAmount1: formik.values.milestone1,
          milestoneAmount2: formik.values.milestone2,
          milestoneAmount3: formik.values.milestone3,
          propertyType: propertyType,
          investmentType: "Ownership",

          //interior
          BuiltInFeature: formik.values.builtIn,
          diningArea: formik.values.diningArea,
          bedroomMainLevel: formik.values.bedroomMainLevel,

          dualSink: sink,
          pantry: pantry,
          sittingArea: sittingArea,
          Bar: bar,
          separateShower: shower,
          walkInClosets: walkInClosets,
          //appliances
          iceMaker: iceMaker,
          dishwasher: dishwasher,
          dryer: dryer,
          refrigerator: refrigerator,
          washingMachine: washer,
          microwave: microwave,
          Oven: oven,
          // heatingCentralFurnace: heating,
          parkingFeatures: parkingFeature,
          exteriorLotFeatures: exteriorFeature,

          //construction
          YearBuilt: formik.values.yearBuilt,
          ContructionMeterial: formik.values.constructionMaterial,
          Flooring: formik.values.flooring,
          Roof: formik.values.roof,
          ArchitecturalStyle: formik.values.architecturalStyle,

          //other
          View: formik.values.view,
          WaterFront: formik.values.waterFront,
          WaterFrontFeature: formik.values.waterfrontFeatures,
          AccessibilityFeature: formik.values.accessibilityFeatures,
          Taxes: formik.values.taxes,
        };
        console.log(body_ownership, "body_ownership");
        try {
          const response = await updateProperty({
            body: body_ownership,
            property_id: id,
          }).unwrap();
          if (response?.statusCode === 200) {
            console.log(response, "resss");
            showToast("Property Updated Successfully");
            navigate("/manage-properties/");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  return (
    <>
      <MainContainer>
        <Loader isLoad={isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Property</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-properties");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Title</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      className="text_field"
                      id="title"
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Location</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="location"
                      variant="outlined"
                      fullWidth
                      placeholder="Location"
                      className="text_field"
                      id="location"
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Property Type
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={propertyType}
                        onChange={handlePropertyType}
                        sx={{ minWidth: "250px" }}
                      >
                        <MenuItem value="Private">Private</MenuItem>
                        <MenuItem value="Residential">Residential</MenuItem>
                        <MenuItem value="Commercial">Commercial</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Property Value
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="propertyValue"
                      variant="outlined"
                      fullWidth
                      placeholder="Location"
                      className="text_field"
                      id="propertyValue"
                      onBlur={formik.handleBlur}
                      value={formik.values.propertyValue}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.propertyValue &&
                        formik.errors.propertyValue
                      }
                    ></TextField>
                  </Grid>
                  {state.propertyType === "Profit Sharing" ? (
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        No of Tokens
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="noOfTokens"
                        variant="outlined"
                        fullWidth
                        placeholder="No of Tokens"
                        className="text_field"
                        id="noOfTokens"
                        onBlur={formik.handleBlur}
                        value={formik.values.noOfTokens}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.noOfTokens && formik.errors.noOfTokens
                        }
                      ></TextField>
                    </Grid>
                  ) : (
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Total Amount
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="totalPropertyAmount"
                        variant="outlined"
                        fullWidth
                        placeholder="Total Amount"
                        className="text_field"
                        id="totalPropertyAmount"
                        onBlur={formik.handleBlur}
                        value={formik.values.totalPropertyAmount}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.totalPropertyAmount &&
                          formik.errors.totalPropertyAmount
                        }
                      ></TextField>
                    </Grid>
                  )}
                  {state.propertyType === "Profit Sharing" ? (
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Profit Expected
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="profitExpected"
                        variant="outlined"
                        fullWidth
                        placeholder="Profit Expected"
                        className="text_field"
                        id="profitExpected"
                        onBlur={formik.handleBlur}
                        value={formik.values.profitExpected}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.profitExpected &&
                          formik.errors.profitExpected
                        }
                      ></TextField>
                    </Grid>
                  ) : (
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Milestone 1
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="milestone1"
                        variant="outlined"
                        fullWidth
                        placeholder="Milestone 1"
                        className="text_field"
                        id="milestone1"
                        onBlur={formik.handleBlur}
                        value={formik.values.milestone1}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.milestone1 && formik.errors.milestone1
                        }
                      ></TextField>
                    </Grid>
                  )}
                  {state.propertyType === "Profit Sharing" ? (
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Date of profit distribution
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          // style={{ maxHeight: "20px" }}
                          className="select_div"
                          placeholder="select"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          value={profitSharingDate}
                          onChange={(event: SelectChangeEvent) =>
                            setProfitSharingDate(event.target.value as string)
                          }
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {Array.from({ length: 28 }, (_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {error && !profitSharingDate ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                      {/* <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder="Date of profit distribution"
                        fullWidth
                        name="dateOfProfitSharing"
                        variant="outlined"
                        type={"date"}
                        id="dateOfProfitSharing"
                        onBlur={formik.handleBlur}
                        value={formik.values.dateOfProfitSharing}
                        onChange={formik.handleChange}
                        helperText={
                          formik.touched.dateOfProfitSharing &&
                          formik.errors.dateOfProfitSharing
                        }
                      /> */}
                    </Grid>
                  ) : (
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Milestone 2
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="milestone2"
                        variant="outlined"
                        fullWidth
                        placeholder="Milestone 2"
                        className="text_field"
                        id="milestone2"
                        onBlur={formik.handleBlur}
                        value={formik.values.milestone2}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.milestone2 && formik.errors.milestone2
                        }
                      ></TextField>
                    </Grid>
                  )}
                  {state.propertyType === "Profit Sharing" ? null : (
                    <Grid item xs={4}>
                      <Typography className="custom_label">
                        Milestone 3
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="milestone3"
                        variant="outlined"
                        fullWidth
                        placeholder=" Milestone 3"
                        className="text_field"
                        id="milestone3"
                        onBlur={formik.handleBlur}
                        value={formik.values.milestone3}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        helperText={
                          formik.touched.milestone3 && formik.errors.milestone3
                        }
                      ></TextField>
                    </Grid>
                  )}

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Description"
                      fullWidth
                      multiline
                      minRows={1.2}
                      maxRows={4}
                      name="description"
                      variant="outlined"
                      id="description"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    ></TextField>
                  </Grid>

                  {/* Interior features */}
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        component="h2"
                        sx={{
                          fontSize: "20px",
                          color: "#cdff00",
                          fontWeight: 700,
                        }}
                      >
                        Interior Features
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Builtin Features
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="builtIn"
                      variant="outlined"
                      fullWidth
                      placeholder="Builtin Features"
                      className="text_field"
                      id="builtIn"
                      onBlur={formik.handleBlur}
                      value={formik.values.builtIn}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.builtIn && formik.errors.builtIn
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Bedroom Main Level
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="bedroomMainLevel"
                      variant="outlined"
                      fullWidth
                      placeholder="Bedroom Main Level"
                      className="text_field"
                      id="bedroomMainLevel"
                      onBlur={formik.handleBlur}
                      value={formik.values.bedroomMainLevel}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.bedroomMainLevel &&
                        formik.errors.bedroomMainLevel
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Dining Area
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="diningArea"
                      variant="outlined"
                      fullWidth
                      placeholder="Bedroom Main Level"
                      className="text_field"
                      id="diningArea"
                      onBlur={formik.handleBlur}
                      value={formik.values.diningArea}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.diningArea && formik.errors.diningArea
                      }
                    ></TextField>
                  </Grid>
                  <CustomSelect
                    label="Pantry"
                    handleChange={(event) =>
                      setPantry(event.target.value as string)
                    }
                    value={pantry}
                  />
                  <CustomSelect
                    label="Sitting Area Primary"
                    handleChange={(event) =>
                      setSittingArea(event.target.value as string)
                    }
                    value={sittingArea}
                  />
                  <CustomSelect
                    label="Dual Sinks"
                    handleChange={(event) =>
                      setSink(event.target.value as string)
                    }
                    value={sink}
                  />
                  <CustomSelect
                    label="Separate Shower"
                    handleChange={(event) =>
                      setShower(event.target.value as string)
                    }
                    value={shower}
                  />
                  <CustomSelect
                    label="Bar"
                    handleChange={(event) =>
                      setBar(event.target.value as string)
                    }
                    value={bar}
                  />
                  <CustomSelect
                    label="Walk in Closets"
                    handleChange={(event) =>
                      setWalkinClosets(event.target.value as string)
                    }
                    value={walkInClosets}
                  />
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        component="h2"
                        sx={{
                          fontSize: "20px",
                          color: "#cdff00",
                          fontWeight: 700,
                        }}
                      >
                        Appliances
                      </Typography>
                    </Box>
                  </Grid>
                  <CustomSelect
                    label="Dishwasher"
                    handleChange={(event) =>
                      setDishwasher(event.target.value as string)
                    }
                    value={dishwasher}
                  />
                  <CustomSelect
                    label="Dryer"
                    handleChange={(event) =>
                      setdryer(event.target.value as string)
                    }
                    value={dryer}
                  />
                  <CustomSelect
                    label="Ice Maker"
                    handleChange={(event) =>
                      setIceMaker(event.target.value as string)
                    }
                    value={iceMaker}
                  />
                  <CustomSelect
                    label="Microwave"
                    handleChange={(event) =>
                      setMicrowave(event.target.value as string)
                    }
                    value={microwave}
                  />
                  <CustomSelect
                    label="Oven"
                    handleChange={(event) =>
                      setOven(event.target.value as string)
                    }
                    value={oven}
                  />
                  <CustomSelect
                    label="Range"
                    handleChange={(event) =>
                      setRange(event.target.value as string)
                    }
                    value={range}
                  />
                  <CustomSelect
                    label="Refrigerator"
                    handleChange={(event) =>
                      setRefrigerator(event.target.value as string)
                    }
                    value={refrigerator}
                  />
                  <CustomSelect
                    label="Washer"
                    handleChange={(event) =>
                      setwasher(event.target.value as string)
                    }
                    value={washer}
                  />
                  <Grid item xs={12}>
                    <div className="form_control">
                      <Typography
                        component="h2"
                        sx={{
                          fontSize: "20px",
                          color: "#cdff00",
                          fontWeight: 700,
                        }}
                      >
                        Exterior and Lot Features
                      </Typography>
                      <ul
                        style={{
                          paddingLeft: 0,
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {exteriorFeature?.map((item: string, i: number) => (
                          <li key={i} className="value_list">
                            {item}
                            <CloseIcon
                              onClick={() => handleDeleteExteriorFeature(i)}
                              sx={{ color: "#cdff00", cursor: "pointer" }}
                            />
                          </li>
                        ))}
                      </ul>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Exterior and lot features"
                          variant="outlined"
                          fullWidth
                          value={exterior}
                          onChange={(e) => setExterior(e.target.value)}
                        />
                        <AddIcon
                          onClick={handleExterior}
                          sx={{ color: "#cdff00", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="form_control">
                      <Typography
                        component="h2"
                        sx={{
                          fontSize: "20px",
                          color: "#cdff00",
                          fontWeight: 700,
                        }}
                      >
                        Parking Features
                      </Typography>
                      <ul
                        style={{
                          paddingLeft: 0,
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {parkingFeature?.map((item: string, i: number) => (
                          <li key={i} className="value_list">
                            {item}
                            <CloseIcon
                              onClick={() => handleDeleteParkingFeature(i)}
                              sx={{ color: "#cdff00", cursor: "pointer" }}
                            />
                          </li>
                        ))}
                      </ul>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          hiddenLabel
                          type="text"
                          placeholder="Parking features"
                          variant="outlined"
                          fullWidth
                          value={parking}
                          onChange={(e) => setParking(e.target.value)}
                        />
                        <AddIcon
                          onClick={handleParking}
                          sx={{ color: "#cdff00", cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        component="h2"
                        sx={{
                          fontSize: "20px",
                          color: "#cdff00",
                          fontWeight: 700,
                        }}
                      >
                        Building and Construction
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Year Built</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      placeholder="Year Built"
                      fullWidth
                      name="dateOfProfitSharing"
                      variant="outlined"
                      type={"date"}
                      id="yearBuilt"
                      onBlur={formik.handleBlur}
                      value={formik.values.yearBuilt}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.yearBuilt && formik.errors.yearBuilt
                      }
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Construction Materials
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="constructionMaterial"
                      variant="outlined"
                      fullWidth
                      placeholder="Construction Materials"
                      className="text_field"
                      id="constructionMaterial"
                      onBlur={formik.handleBlur}
                      value={formik.values.constructionMaterial}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.constructionMaterial &&
                        formik.errors.constructionMaterial
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Flooring</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="flooring"
                      variant="outlined"
                      fullWidth
                      placeholder="Flooring"
                      className="text_field"
                      id="flooring"
                      onBlur={formik.handleBlur}
                      value={formik.values.flooring}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.flooring && formik.errors.flooring
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Roof</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="roof"
                      variant="outlined"
                      fullWidth
                      placeholder="Roof"
                      className="text_field"
                      id="roof"
                      onBlur={formik.handleBlur}
                      value={formik.values.roof}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.roof && formik.errors.roof}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Architectural style
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="architecturalStyle"
                      variant="outlined"
                      fullWidth
                      placeholder="Architectural style"
                      className="text_field"
                      id="architecturalStyle"
                      onBlur={formik.handleBlur}
                      value={formik.values.architecturalStyle}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.architecturalStyle &&
                        formik.errors.architecturalStyle
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography
                        component="h2"
                        sx={{
                          fontSize: "20px",
                          color: "#cdff00",
                          fontWeight: 700,
                        }}
                      >
                        Other
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">View</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="view"
                      variant="outlined"
                      fullWidth
                      placeholder="View"
                      className="text_field"
                      id="view"
                      onBlur={formik.handleBlur}
                      value={formik.values.view}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.view && formik.errors.view}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Waterfront</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="waterFront"
                      variant="outlined"
                      fullWidth
                      placeholder="Waterfront"
                      className="text_field"
                      id="waterFront"
                      onBlur={formik.handleBlur}
                      value={formik.values.waterFront}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.waterFront && formik.errors.waterFront
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Waterfront features
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="waterfrontFeatures"
                      variant="outlined"
                      fullWidth
                      placeholder="Waterfront features"
                      className="text_field"
                      id="waterfrontFeatures"
                      onBlur={formik.handleBlur}
                      value={formik.values.waterfrontFeatures}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.waterfrontFeatures &&
                        formik.errors.waterfrontFeatures
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Accessibility features
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="accessibilityFeatures"
                      variant="outlined"
                      fullWidth
                      placeholder=" Accessibility features"
                      className="text_field"
                      id="accessibilityFeatures"
                      onBlur={formik.handleBlur}
                      value={formik.values.accessibilityFeatures}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.accessibilityFeatures &&
                        formik.errors.accessibilityFeatures
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Taxes</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="taxes"
                      variant="outlined"
                      fullWidth
                      placeholder="Taxes"
                      className="text_field"
                      id="taxes"
                      onBlur={formik.handleBlur}
                      value={formik.values.taxes}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.taxes && formik.errors.taxes}
                    ></TextField>
                  </Grid>
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default EditProperties;
