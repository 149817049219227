export const API_URL =
  "https://realestatephase1api.appgrowthcompany.com/api/v1/admin"; // development

export const END_POINTS = {
  // auth
  login: "/login",
  forgotPassword: "/forgotPassword",
  get_user: "/getProfile",
  update_profile: "/updateProfile",
  mediaUpload: "upload",
  verifyOtp: "/verifyOtp",
  resendOtp: "/sendOtp",
  resetPassword: "/resetPassword",
  changePassword: "/changePassword",

  // dashboard
  getDashboardData: "/getDashboardData",

  // users
  getAllUsers: "/getAllUser",
  getUserById: "/getUserById",
  approveUser: "/approveUser",
  rejectUser: "/rejectUser",
  deleteUserById: "/deleteUser",
  exportUserCSV: "/exportToCSV",
  editUser: "/updateUser",
  user_status: "/blockUnblockUser",
  getAllListedPropertyByUser: "/getAllListedPropertyByUser",
  getAllPurchasedPropertyByUser: "/purchasedTokens",

  //  requestedProperties
  getRequestedProperties: "/getRequestedProperties",
  getRequestedPropertyById: "getRequestedPropertyById",
  approveProperty: "/approveProperty",
  rejectProperty: "/disApproveProperty",
  deleteRequestedProperty: "/removePropertyById",

  // manageProperties
  getApprovedProperties: "/getProperties",
  getApprovedPropertyById: "/getPropertyById",
  propertyStatus: "/blockUnblockProperty",
  deleteProperty: "/deleteProperty",
  updateProperty: "/updateProperty",
  setProfit: "/setProfit",
  getProfits: "/getProfits",

  // cms
  get_cms: "/getCms",
  addAboutUs: "/updateAboutUs",
  getAboutUs: "/getAboutUs",
  addCms: "/addCms",
  updateCms: "/updateCms",
  addFaq: "/addFaq",
  getFaq: "/getFaq",
  updateFaq: "/updateFaq",
  deleteFaq: "/deleteFaq",

  // customerSuppoprt
  getAllTickets: "/getAllTickets",
  replies: "/replies",
  getTicketsById: "/getTicketsById",
  deleteTicketById: "/deleteTicketById",

  //blogs
  createBlog: "/createBlog",
  getAllBlogs: "/getAllBlogs",
  getBlogById: "/getBlogById",
  updateBlog: "/updateBlog",
  deleteBlog: "/deleteBlog",
  blockUnblockBlog: "/blockUnblockBlog",

  //subadmin
  getSubAdmin: "/getSubAdmin",
  addSubAdmin: "/addSubAdmin",
  updateSubAdmin: "/updateSubAdmin",
  deleteSubAdmin: "/deleteSubAdmin",
  blockUnblockSubAdmin: "/blockUnblockSubAdmin",

  //news
  createNews: "/createNews",
  getAllNews: "/getAllNews",
  getNewsById: "/getNewsById",
  updateNews: "/updateNews",
  deleteNews: "/deleteNews",
  blockUnblockNews: "/blockUnblockNews",

  //transactions
  getAllTransactions: "/getAllTransactions",

  //report
  getAllReport: "getAllReport",
  revertReport: "revertOnReport",
  review: "getAllReview",
};
