import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";

import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { AllRequestedProperties } from "../../types/General";
import {
  useLazyDeleteRequestedPropertyQuery,
  useLazyGetRequestedPropertiesQuery,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { Pagination, WarnModal } from "../../components";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";

const ManageRequests = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [requestedProperties, setRequestedProperties] = useState<
    AllRequestedProperties[]
  >([]);
  const [deleteRequestedPropertyById] = useLazyDeleteRequestedPropertyQuery();

  const [getRequestedPropertiesList, { isLoading }] =
    useLazyGetRequestedPropertiesQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getRequestedProperties = async () => {
    try {
      const response = await getRequestedPropertiesList({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setRequestedProperties(response?.data?.property || []);
        setTotalCount(response?.data?.propertyCount);
      } else {
        setRequestedProperties([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const handleDelete = async (id: string) => {
    try {
      const response = await deleteRequestedPropertyById({
        property_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        const res = await getRequestedPropertiesList({
          page: page,
          limit: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setRequestedProperties(res?.data?.property || []);
        } else {
          setRequestedProperties([]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permission = userData?.permissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Requests"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getRequestedProperties();
  }, [debouncedSearchTerm, page]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Requests</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right"></Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Property Name</TableCell>
                  <TableCell align="center">Location</TableCell>
                  <TableCell align="center">User Details</TableCell>

                  <TableCell align="center">Investment Type</TableCell>
                  <TableCell align="center">Request Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              {requestedProperties?.length ? (
                requestedProperties?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell align="center">
                          {item?.propertyTitle || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {item?.propertyAddress || "-"}
                        </TableCell>
                        <TableCell>
                          {/* <p style={{ margin: 0, padding: "2px 0" }}>{item?.}</p> */}
                          <p style={{ margin: 0, padding: "2px 0" }}>
                            {item?.email || "-"}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          {item?.investmentType || "-"}
                        </TableCell>
                        <TableCell align="center">
                          {item?.propertyStatus || "-"}
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-requests/requestdetails/${item?._id}`,
                                  {
                                    state: {
                                      propertyType: item?.investmentType,
                                    },
                                  }
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "white" }}
                  >
                    No Request Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={requestedProperties}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="requested property"
      />
    </MainContainer>
  );
};

export default ManageRequests;
