import { END_POINTS } from "../constants/url";
import { Faqs, QueryDetailResponse, QueryResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetAllQueriesResponse = {
  statusCode: number;
  message: string;
  data: {
    ticketCount: number;
    ticket: QueryResponse[];
  };
};
type PostReplyBody = {
  body: {
    message: string;
  };
  query_id: string;
};

type PostReportBody = {
  body: {
    revertMessage: string;
  };
  query_id: string;
};

type GetQueryResponse = {
  statusCode: number;
  message: string;
  data: QueryDetailResponse[];
};
export const customerSupportApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllQueries: builder.query<
      GetAllQueriesResponse,
      { status: string; page: number; limit: number; query: string }
    >({
      query: ({ status, page, limit, query }) => ({
        url:
          END_POINTS.getAllTickets +
          "?status=" +
          status +
          "&page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    postReply: builder.mutation<GetAllQueriesResponse, PostReplyBody>({
      query: ({ query_id, body }) => ({
        url: `${END_POINTS.replies}/${query_id}`,
        method: "POST",
        body,
      }),
    }),
    getTicketsById: builder.query<GetQueryResponse, { query_id: string }>({
      query: ({ query_id }) => ({
        url: `${END_POINTS.getTicketsById}/${query_id}`,
        method: "GET",
      }),
    }),
    deleteTicketById: builder.query<GetQueryResponse, { query_id: string }>({
      query: ({ query_id }) => ({
        url: `${END_POINTS.deleteTicketById}/${query_id}`,
        method: "DELETE",
      }),
    }),
    postReport: builder.mutation<GetAllQueriesResponse, PostReportBody>({
      query: ({ query_id, body }) => ({
        url: `${END_POINTS.revertReport}/${query_id}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAllQueriesQuery,
  usePostReplyMutation,
  useLazyGetTicketsByIdQuery,
  useLazyDeleteTicketByIdQuery,
  usePostReportMutation,
} = customerSupportApi;
