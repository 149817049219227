import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import { Rating } from "react-simple-star-rating";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { AllRequestedProperties } from "../../types/General";
import {
  useLazyChangePropertyStatusQuery,
  useLazyDeletePropertyIdQuery,
  useLazyGetApprovedPropertiesQuery,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { Pagination, WarnModal } from "../../components";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { Rating } from "react-simple-star-rating";

const ManageProperties = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [approvedProperties, setApprovedProperties] = useState<
    AllRequestedProperties[]
  >([]);
  const [propertyStatus] = useLazyChangePropertyStatusQuery();

  const [getApprovedPropertiesList, { isLoading }] =
    useLazyGetApprovedPropertiesQuery();
  const [deletePropertyById] = useLazyDeletePropertyIdQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const checkPermission = () => {
    const permission = userData?.permissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Properties"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getApprovedProperties = async () => {
    try {
      const response = await getApprovedPropertiesList({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setApprovedProperties(response?.data?.property || []);
        setTotalCount(response?.data?.propertyCount);
      } else {
        setApprovedProperties([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const updateStatus = async (id: string) => {
    try {
      const response = await propertyStatus({ property_id: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        const res = await getApprovedPropertiesList({
          page: page,
          limit: 10,
          query: searchTerm,
        }).unwrap();
        if (res?.statusCode === 200) {
          setApprovedProperties(res?.data?.property || []);
        } else {
          setApprovedProperties([]);
        }
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deletePropertyById({
        property_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        const res = await getApprovedPropertiesList({
          page: page,
          limit: 10,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setApprovedProperties(res?.data?.property || []);
          setTotalCount(res?.data?.propertyCount);
        } else {
          setApprovedProperties([]);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    getApprovedProperties();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Properties</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>User Details</TableCell>
                  <TableCell>Property Name</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Investment Type</TableCell>
                  <TableCell>Rating</TableCell>
                  <TableCell>Total Tokens</TableCell>
                  <TableCell>Tokens Sold</TableCell>
                  <TableCell>Set Profit</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {approvedProperties?.length ? (
                approvedProperties?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          {/* <p style={{ margin: 0, padding: "2px 0" }}>User</p> */}
                          <p style={{ margin: 0, padding: "2px 0" }}>
                            {item?.email || "-"}
                          </p>
                        </TableCell>
                        <TableCell>{item?.propertyTitle || "-"}</TableCell>
                        <TableCell> {item?.propertyAddress || "-"}</TableCell>
                        <TableCell>{item?.investmentType || "-"}</TableCell>
                        <TableCell>
                          <Rating
                            initialValue={item?.propertyRating || 0}
                            // onClick={(newRating: number) =>
                            //   setratingCount(newRating)
                            // }
                            iconsCount={5}
                            size={23}
                            allowHover={false}
                            readonly
                            // transition={true}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {item?.totalTokens || "-"}
                        </TableCell>

                        <TableCell align="center">
                          {" "}
                          {item?.soldTokens || "-"}
                        </TableCell>
                        <TableCell>
                          {item?.investmentType === "Profit Sharing" ? (
                            <p
                              style={{
                                color: "#cdff00",
                                fontWeight: "600",
                                margin: "2px 0",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(
                                  `/manage-properties/set-profit/${item?._id}`,
                                  { state: { propertyId: item?.propertyId } }
                                )
                              }
                            >
                              {" "}
                              Set Profit{" "}
                            </p>
                          ) : (
                            <p>-</p>
                          )}
                        </TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={!item?.isBlocked}
                            onChange={() => updateStatus(item?._id)}
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-properties/details/${item?._id}`,
                                  {
                                    state: {
                                      propertyType: item?.investmentType,
                                    },
                                  }
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {hidePermission?.isEdit ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-properties/edit/${item?._id}`,
                                    {
                                      state: {
                                        propertyType: item?.investmentType,
                                      },
                                    }
                                  )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "white" }}
                  >
                    No Properties Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={approvedProperties}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name=" property"
      />
    </MainContainer>
  );
};

export default ManageProperties;
