import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../../components/SearchBar";
import { PurchasedPropertiesByUserId } from "../../../types/General";

type PurchasedPropertiesTYpe = {
  value2: number;
  page: number;
  onPageChange: (newPage: number) => void;
  totalPages: number;
  purchasedProperties: PurchasedPropertiesByUserId[];
};

const UserPurchasedProperties = ({
  value2,
  page,
  onPageChange,
  totalPages,
  purchasedProperties,
}: PurchasedPropertiesTYpe) => {
  return (
    <>
      <Box className="cards_header">{/* <SearchBar /> */}</Box>
      {value2 === 0 ? (
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell> Title</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Total Amount</TableCell>
              </TableRow>
            </TableHead>
            {purchasedProperties?.length ? (
              purchasedProperties?.map((item, i) => {
                return (
                  <TableBody key={item?._id}>
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>{item?.propertyTitle || "-"}</TableCell>
                      <TableCell>{item?.propertyAddress || "-"}</TableCell>
                      <TableCell>{item?.propertyType || "-"}</TableCell>
                      <TableCell>{item?.tokenExpense || "-"}</TableCell>
                    </TableRow>
                  </TableBody>
                );
              })
            ) : (
              <TableBody>
                <TableCell align="center" colSpan={10} sx={{ color: "white" }}>
                  No Properties Found
                </TableCell>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell> Title</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Property Type</TableCell>
                <TableCell>Total no of Tokens</TableCell>
                <TableCell> Monthly profit</TableCell>
                <TableCell>Monthly Expenses</TableCell>
              </TableRow>
            </TableHead>
            {purchasedProperties?.length ? (
              purchasedProperties?.map((item, i) => {
                return (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>{item?.propertyTitle || "-"}</TableCell>
                      <TableCell>{item?.propertyAddress || "-"}</TableCell>
                      <TableCell>{item?.propertyType || "-"}</TableCell>
                      <TableCell>{item?.boughtTokens || "-"}</TableCell>
                      <TableCell>{"-"}</TableCell>
                      <TableCell>{item?.tokenExpense || "-"}</TableCell>
                    </TableRow>
                  </TableBody>
                );
              })
            ) : (
              <TableBody>
                <TableCell align="center" colSpan={10} sx={{ color: "white" }}>
                  No Properties Found
                </TableCell>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </>
  );
};
export default UserPurchasedProperties;
