import { END_POINTS } from "../constants/url";
import { GetSubadminResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllSubadminsResponse = {
  subAdminCount: number;
  subAdmin: GetSubadminResponse[];
};

type GetSubadminResponseType = {
  fullName: string;
  email: string;
  phone: string;
  profileImage: string;
  countryCode: string;
  isProfileCompleted: false;
  isEmailVerify: false;
  isPhoneVerify: false;
  isDeleted: false;
  isBlocked: boolean;
  role: string;
  roleType: string;
  subAdminId: {
    _id: string;
    permissions: {
      label: string;
      isView: boolean;
      isEdit: boolean;
      isAdd: boolean;
      _id: boolean;
    }[];
  };

  _id: string;
};

type PostSubadminBody = {
  email: string;
  fullName: string;
  countryCode: string;
  phone: string;
  profileImage: string;
  password?: string;
  permissions: {
    label?: string | undefined;
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }[];
};

export const subadminApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubAdmins: builder.query<
      CommonResponseType & { data: GetAllSubadminsResponse },
      { page: number; limit: number; query: string }
    >({
      query: ({ page, limit, query }) => ({
        url:
          END_POINTS.getSubAdmin +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    postSubadmin: builder.mutation<
      CommonResponseType & { data: GetSubadminResponseType },
      PostSubadminBody
    >({
      query: (body) => ({
        url: `${END_POINTS.addSubAdmin}`,
        method: "POST",
        body,
      }),
    }),
    changeSubAdminStatus: builder.query<
      CommonResponseType & { data: GetAllSubadminsResponse },
      { sub_id: string }
    >({
      query: ({ sub_id }) => ({
        url: `${END_POINTS.blockUnblockSubAdmin}/${sub_id}`,
        method: "GET",
      }),
    }),
    getSubadminById: builder.query<
      CommonResponseType & { data: GetSubadminResponse },
      { sub_id: string }
    >({
      query: ({ sub_id }) => ({
        url: `${END_POINTS.getSubAdmin}/${sub_id}`,
        method: "GET",
      }),
    }),
    updateSubAdmin: builder.mutation<
      CommonResponseType & { data: GetSubadminResponseType },
      { update_body: PostSubadminBody; sub_id: string }
    >({
      query: ({ update_body, sub_id }) => ({
        url: `${END_POINTS.updateSubAdmin}/${sub_id}`,
        method: "PUT",
        body: update_body,
      }),
    }),
    deleteSubAdminById: builder.query<
      CommonResponseType & { data: GetAllSubadminsResponse },
      { subadmin_id: string }
    >({
      query: ({ subadmin_id }) => ({
        url: `${END_POINTS.deleteSubAdmin}/${subadmin_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetAllSubAdminsQuery,
  usePostSubadminMutation,
  useLazyGetSubadminByIdQuery,
  useLazyChangeSubAdminStatusQuery,
  useUpdateSubAdminMutation,
  useLazyDeleteSubAdminByIdQuery,

  //   useLazyGetBlogsByIdQuery,
} = subadminApi;
