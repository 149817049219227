import {
  Box,
  Button,
  Card,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { showError, showToast } from "../../constants";
import { UploadMedia } from "../../utils/mediaUpload";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  AboutUsRes,
  useLazyGetAboutUsQuery,
  usePostAboutUsMutation,
} from "../../services/cms";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

interface TeamMember {
  name: string;
  imagePath: string;
  Designation: string;
}

type PropType = {
  hidePermission?: Permissions | null | undefined;
};
const AboutUs = ({ hidePermission }: PropType) => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [AboutUsMutation] = usePostAboutUsMutation();
  const [error, setError] = useState(false);
  const [teamName, setTeamName] = useState<string>("");
  const [teamDesignation, setTeamDesignation] = useState<string>("");
  const [teamImage, setTeamImage] = useState<string>("");
  const [teamData, setTeamData] = useState<TeamMember[]>([]);
  const [abousUsData, setAboutUsData] = useState<AboutUsRes>();

  const handleAddTeam = () => {
    if (teamName == "" || teamDesignation == "" || !teamImage) {
      setError(true);
    } else if (teamName !== "" && teamDesignation !== "" && teamImage) {
      setError(false);
      const newTeamMember: TeamMember = {
        name: teamName,
        Designation: teamDesignation,
        imagePath: teamImage,
      };
      setTeamData([...teamData, newTeamMember]);
      setTeamName("");
      setTeamDesignation("");
      setTeamImage("");
    }
  };
  const deleteTeamMember = (index: number) => {
    let arr1 = [];
    arr1.push(...teamData.slice(0, index), ...teamData.slice(index + 1));
    setTeamData([...arr1]);
  };

  const handleTeamImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setTeamImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const [getAboutUs] = useLazyGetAboutUsQuery();

  const handleGetAboutUs = async () => {
    try {
      const result = await getAboutUs({}).unwrap();
      if (result?.statusCode === 200) {
        setAboutUsData(result?.data[0]);
        setTeamData(result?.data[0]?.team || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAboutUs();
  }, []);

  const updateAboutUs = async () => {
    if (!teamData?.length) {
      setError(true);
    } else {
      setError(false);
      const body = {
        team: teamData,
      };
      try {
        const response = await AboutUsMutation(body).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "");
          const result = await getAboutUs({}).unwrap();
          if (result?.statusCode === 200) {
            setAboutUsData(result?.data[0]);
            setTeamData(result?.data[0]?.team || []);
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      {/* <Loader isLoad={isLoading} /> */}
      <Grid item xs={12}>
        <Box className="faq_box nw_bx">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Typography
                  component="h2"
                  sx={{ fontWeight: 700, fontSize: "22px", color: "#cdff00" }}
                >
                  Our Team
                </Typography>
              </Box>
            </Grid>
            {teamData?.length ? (
              <Grid item xs={12} className="our_team_mn">
                {teamData?.map((item, i) => {
                  return (
                    <div className="our_team_sec" key={i}>
                      {hidePermission?.isDelete ||
                      userData?.role === "ADMIN" ? (
                        <DeleteIcon onClick={() => deleteTeamMember(i)} />
                      ) : null}
                      <figure>
                        <img src={item?.imagePath} alt="" />
                      </figure>
                      <h4>{item?.name}</h4>
                      <p>{item?.Designation}</p>
                    </div>
                  );
                })}
              </Grid>
            ) : null}
            {(hidePermission?.isEdit || userData?.role === "ADMIN") &&
            teamData?.length <= 8 ? (
              <>
                <Grid item xs={12}>
                  <Typography className="custom_label">Image</Typography>
                  {teamImage ? (
                    <div className="upload_image_preview2">
                      <CardMedia
                        component="img"
                        image={
                          teamImage !== ""
                            ? teamImage
                            : "/static/images/blog_img.jpeg"
                        }
                        alt="photo"
                      />
                      <CancelIcon
                        sx={{ color: "#cdff00" }}
                        onClick={() => {
                          setTeamImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={handleTeamImageUpload}
                        />
                        <Button component="span" className="upload_image_dv">
                          <AddIcon className="add_icn_btn" />
                        </Button>
                      </label>
                    </Box>
                  )}
                  {error && !teamImage ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={5}>
                  <Typography className="custom_label">Name</Typography>
                  <TextField
                    className="text_field"
                    fullWidth
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    value={teamName}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        setTeamName(val.target.value);
                      }
                    }}
                    placeholder="Name"
                  ></TextField>
                  {error && !teamName ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={5}>
                  <Typography className="custom_label">Designation</Typography>
                  <TextField
                    className="text_field"
                    fullWidth
                    hiddenLabel
                    type={"text"}
                    variant="outlined"
                    value={teamDesignation}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        setTeamDesignation(val.target.value);
                      }
                    }}
                    placeholder="Designation"
                  />
                  {error && !teamDesignation ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <IconButton style={{ marginTop: 20, background: "#cdff00" }}>
                    <AddCircleIcon onClick={handleAddTeam} />
                  </IconButton>
                </Grid>
              </>
            ) : null}

            {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
              <Grid item xs={2}>
                <Button
                  className="btn_primary"
                  sx={{ minHeight: " 50px" }}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={updateAboutUs}
                >
                  Save
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
