import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Dialog,
  DialogContent,
  FormControl,
  TextField,
  IconButton,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetPropertyByIdQuery } from "../../services/main";
import ReplyIcon from "@mui/icons-material/Reply";

import {
  ReportRes,
  RequestedProperty,
  TransactionsResponse,
} from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { isString, useFormik } from "formik";
import * as Yup from "yup";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  useLazyGetAllReportQuery,
  useLazyGetAllReviewsQuery,
} from "../../services/news";
import { usePostReportMutation } from "../../services/customerSupport";
import { Rating } from "react-simple-star-rating";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PropertyDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const location = useLocation();
  const { state } = location;
  const [getPropertyDeatilById, { isLoading }] = useLazyGetPropertyByIdQuery();
  const [propertyData, setPropertyData] = useState<RequestedProperty>();
  const [open_gallery, setOpen_gallery] = useState(false);
  const [openVideos, setOpenVideos] = useState(false);

  const getPropertyDetail = async (id: string) => {
    try {
      const response = await getPropertyDeatilById({
        property_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setPropertyData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getPropertyDetail(id);
    }
  }, []);

  function getDaySuffix(day: number) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  const [getReportMutation] = useLazyGetAllReportQuery();
  const [getReview] = useLazyGetAllReviewsQuery();
  const [postReport] = usePostReportMutation();

  const [reportData, setReportData] = useState<ReportRes[]>([]);
  console.log("reportData: ", reportData);
  const [reviewData, setReviewData] = useState<ReportRes[]>([]);
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [reportMsg, setReportMsg] = useState<string>("");

  const handleClose = () => {
    setOpenQueryModal(false);
    formik.setFieldError("message", "");
  };

  const getReport = async (id: string) => {
    try {
      const response = await getReportMutation({
        id,
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log("response: ", response);
        setReportData(response?.data?.report || []);
      } else {
        setReportData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleGetReview = async (id: string) => {
    try {
      const response = await getReview({
        id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setReviewData(response?.data?.review || []);
      } else {
        setReviewData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        revertMessage: formik.values.message,
      };

      console.log(body, "bodybody");

      try {
        const response = await postReport({
          query_id: selectedId,
          body,
        }).unwrap();
        showToast(response?.message || "");
        setOpenQueryModal(false);
      } catch (error: any) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (id) {
      getReport(id);
      handleGetReview(id);
    }
  }, [id]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Property Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-properties");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              {state?.propertyType === "Profit Sharing" ? (
                <Grid item xs={10} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">User Details</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Username</Typography>
                        <Typography component="p">
                          {`  ${propertyData?.firstName} ${propertyData?.lastName}` ||
                            "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Email</Typography>
                        <Typography component="p">
                          {propertyData?.email || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Contact No</Typography>
                        <Typography component="p">
                          {propertyData?.countryCode
                            ? (propertyData?.countryCode.includes("+")
                                ? ""
                                : "+") + propertyData?.countryCode
                            : null}{" "}
                          {propertyData?.phone || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Details</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Title</Typography>
                        <Typography component="p">
                          {propertyData?.propertyTitle || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Location</Typography>
                        <Typography component="p">
                          {propertyData?.propertyAddress || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Investment Type</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.investmentType || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Property Type</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.propertyType || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">No. of Tokens</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.totalTokens || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Profit Expected</Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.expectedProfit}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Profit Received</Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.profitRecieved}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Profit Remaining</Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.remainingProfit}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">
                          Date of profit distribution
                        </Typography>
                        <Typography component="p">
                          {/* {moment(propertyData?.profitDistributionDate).format(
                            "DD/MM/YYYY"
                          )} */}
                          {propertyData?.profitDistributionDate || ""}
                          {getDaySuffix(
                            Number(propertyData?.profitDistributionDate)
                          )}{" "}
                          of every month
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">No of rooms</Typography>
                        <Typography component="p">
                          {propertyData?.bedroomsCount || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Approval Status</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.propertyStatus || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Description</Typography>
                        <Typography component="p">
                          {propertyData?.propertyDescription || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">
                          Uploaded Documents
                        </Typography>
                        <Box className="docs_div">
                          {propertyData?.ownershipDocuments?.length
                            ? propertyData?.ownershipDocuments?.map((item) => {
                                return (
                                  <figure className="doc_img">
                                    <img src={item} />
                                  </figure>
                                );
                              })
                            : null}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Images</Typography>
                      </Box>
                      <Box className="other_gallery">
                        <figure>
                          <img
                            src={propertyData?.propertyImages?.[0] || ""}
                            alt=""
                          />
                          <span
                            className="more"
                            onClick={() => setOpen_gallery(true)}
                          >
                            <img src="/images/icon_image.svg" alt="" />
                            Show all photos
                          </span>
                        </figure>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Videos</Typography>
                      </Box>
                      <Box className="other_gallery">
                        <figure>
                          <img
                            src={propertyData?.propertyImages?.[1] || ""}
                            alt=""
                          />
                          <span
                            className="more"
                            onClick={() => setOpenVideos(true)}
                          >
                            <img src="/images/icon_image.svg" alt="" />
                            Show all videos
                          </span>
                        </figure>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={10} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">User Details</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Username</Typography>
                        <Typography component="p">
                          {" "}
                          {`  ${propertyData?.firstName} ${propertyData?.lastName}` ||
                            "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Email</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.email || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography component="h5">Contact No</Typography>
                        <Typography component="p">
                          {propertyData?.countryCode
                            ? (propertyData?.countryCode.includes("+")
                                ? ""
                                : "+") + propertyData?.countryCode
                            : null}{" "}
                          {propertyData?.phone || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Details</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Title</Typography>
                        <Typography component="p">
                          {propertyData?.propertyTitle || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Location</Typography>
                        <Typography component="p">
                          {propertyData?.propertyAddress || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Investment Type</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.investmentType || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Property Type</Typography>
                        <Typography component="p">
                          {" "}
                          {propertyData?.propertyType || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Total Amount</Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.totalInvestment}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">
                          Milestone 1 (Upfront)
                        </Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.milestoneAmount1}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">
                          Milestone 2 (Contract Signing)
                        </Typography>
                        <Typography component="p">
                          {`USDT ${propertyData?.milestoneAmount2}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">
                          Milestone 3 (Contract Transfer)
                        </Typography>
                        <Typography component="p">
                          {" "}
                          {`USDT ${propertyData?.milestoneAmount3}` || "-"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={3}>
                      <Box>
                        <Typography component="h5">Approval Status</Typography>
                        <Typography component="p">
                          {propertyData?.propertyStatus || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">Description</Typography>
                        <Typography component="p">
                          {propertyData?.propertyDescription || "-"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h5">
                          Uploaded Documents
                        </Typography>
                        <Box className="docs_div">
                          {propertyData?.ownershipDocuments?.length
                            ? propertyData?.ownershipDocuments?.map((item) => {
                                return (
                                  <figure className="doc_img">
                                    <img src={item} />
                                  </figure>
                                );
                              })
                            : null}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Images</Typography>
                      </Box>
                      <Box className="other_gallery">
                        <figure>
                          <img
                            src={propertyData?.propertyImages?.[0] || ""}
                            alt=""
                          />
                          <span
                            className="more"
                            onClick={() => setOpen_gallery(true)}
                          >
                            <img src="/images/icon_image.svg" alt="" />
                            Show all photos
                          </span>
                        </figure>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography component="h2">Property Videos</Typography>
                      </Box>
                      <Box className="other_gallery">
                        <figure>
                          <img
                            src={propertyData?.propertyImages?.[1] || ""}
                            alt=""
                          />
                          <span
                            className="more"
                            onClick={() => setOpenVideos(true)}
                          >
                            <img src="/images/icon_image.svg" alt="" />
                            Show all videos
                          </span>
                        </figure>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Typography
              component="h2"
              className="reviewdiv"
              onClick={() => setReviewModal(true)}
            >
              Click here to See Reviews
            </Typography>
            <Box>
              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Interior features</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>Builtin Features</th>
                      <td>{propertyData?.BuiltInFeature || "-"}</td>
                      <th>Separate Formal Dining Room</th>
                      <td>{propertyData?.SeparateFormalDiningRoom || "-"}</td>
                    </tr>
                    <tr>
                      <th>Bedroom Main Level</th>
                      <td>{propertyData?.bedroomMainLevel || "-"}</td>
                      <th>Dual Sinks</th>
                      <td>{propertyData?.dualSink || "-"}</td>
                    </tr>
                    <tr>
                      <th>Dining Area</th>
                      <td>{propertyData?.diningArea || "-"}</td>
                      <th>Separate Shower</th>
                      <td>{propertyData?.separateShower || "-"}</td>
                    </tr>
                    <tr>
                      <th>Kitchen Island</th>
                      <td>{propertyData?.kitchenIsland || "-"}</td>
                      <th>Upper Level Primary</th>
                      <td>{propertyData?.upperLevelPrimary || "-"}</td>
                    </tr>
                    <tr>
                      <th>Pantry</th>
                      <td>{propertyData?.pantry || "-"}</td>
                      <th>Bar</th>
                      <td>{propertyData?.Bar || "-"}</td>
                    </tr>
                    <tr>
                      <th>Sitting Area Primary</th>
                      <td>{propertyData?.sittingArea || "-"}</td>
                      <th>Walk In Closets</th>
                      <td>{propertyData?.walkInClosets || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Appliances</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>Dishwasher</th>
                      <td>{propertyData?.dishwasher || "-"}</td>
                      <th>Oven</th>
                      <td>{propertyData?.Oven || "-"}</td>
                    </tr>
                    <tr>
                      <th>Dryer</th>
                      <td>{propertyData?.dryer || "-"}</td>
                      <th>Range</th>
                      <td>{propertyData?.Range || "-"}</td>
                    </tr>
                    <tr>
                      <th>Ice Maker</th>
                      <td>{propertyData?.iceMaker || "-"}</td>
                      <th>Refrigerator</th>
                      <td>{propertyData?.refrigerator || "-"}</td>
                    </tr>
                    <tr>
                      <th>Microwave</th>
                      <td>{propertyData?.microwave || "-"}</td>
                      <th>Washer</th>
                      <td>{propertyData?.washingMachine || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {propertyData?.exteriorLotFeatures?.length ? (
                <div className="box w_100">
                  <h3 style={{ color: "#cdff00" }}>
                    Exterior and lot features
                  </h3>
                  <table>
                    <tbody>
                      {propertyData?.exteriorLotFeatures?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
              {propertyData?.parkingFeatures?.length ? (
                <div className="box w_100">
                  <h3 style={{ color: "#cdff00" }}>Parking features</h3>
                  <table>
                    <tbody>
                      {propertyData?.parkingFeatures?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Building and Construction</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>Year built</th>
                      <td>
                        {moment(propertyData?.YearBuilt).format("YYYY") || "-"}
                      </td>
                      <th>Flooring</th>
                      <td>{propertyData?.Flooring || "-"}</td>
                    </tr>
                    <tr>
                      <th>Construction materials</th>
                      <td>{propertyData?.ContructionMeterial || "-"}</td>
                      <th>Roof</th>
                      <td>{propertyData?.Roof || "-"}</td>
                    </tr>
                    <tr>
                      <th>Architectural style</th>
                      <td>{propertyData?.ArchitecturalStyle || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Other</h3>
                <table>
                  <tbody>
                    <tr>
                      <th>View</th>
                      <td>{propertyData?.View || "-"}</td>
                      <th>Waterfront features</th>
                      <td>{propertyData?.WaterFrontFeature || "-"}</td>
                    </tr>
                    <tr>
                      <th>Waterfront</th>
                      <td>{propertyData?.WaterFront || "-"}</td>
                      <th>Accessibility features</th>
                      <td>{propertyData?.AccessibilityFeature || "-"}</td>
                    </tr>
                    <tr>
                      <th>Waterfront features</th>
                      <td>{propertyData?.WaterFrontFeature || "-"}</td>
                      <th>Taxes</th>
                      <td>{propertyData?.Taxes || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* report */}
              {/* {reportData?.length ? ( */}
              <div className="box w_100">
                <h3 style={{ color: "#cdff00" }}>Reports</h3>
                <table>
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>User Email</th>
                      <th>Report</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData?.length ? (
                      reportData.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.userName || ""}</td>

                          <td>{item?.userEmail || ""}</td>
                          <td>
                            {item?.reportMessage?.length >= 16
                              ? item?.reportMessage?.slice(0, 16) + "..."
                              : item?.reportMessage}
                          </td>
                          <td>
                            <div className="table_actions_revert">
                              <IconButton
                                onClick={() => {
                                  setOpenQueryModal(true);
                                  if (id) {
                                    setSelectedId(id);
                                  }
                                  setReportMsg(item?.reportMessage);
                                }}
                              >
                                <ReplyIcon />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} style={{ textAlign: "center" }}>
                          No Reports Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* ) : null} */}
            </Box>
          </CardContent>
        </Card>
      </div>
      <Modal
        className="modal gallery_modal"
        open={open_gallery}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open_gallery}>
          <Box className="modalBox_body">
            <Box>
              <Button
                className="close_btn"
                onClick={() => setOpen_gallery(false)}
              >
                <CloseIcon />
              </Button>
              <Swiper
                className="location_swiper gallery_swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  clickable: true,
                }}
                loop
                observer
              >
                <div className="swiper_action">
                  <div className="swiper-button-prev">
                    <ArrowBackIcon />
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next">
                    <ArrowForwardIcon />
                  </div>
                </div>
                {propertyData?.propertyImages?.length
                  ? propertyData?.propertyImages?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <figure>
                          <img
                            src={item || "/images/property-detail-01.jpg"}
                            alt=""
                          />
                        </figure>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Modal
        className="modal gallery_modal"
        open={openVideos}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openVideos}>
          <Box className="modalBox_body">
            <Box>
              <Button
                className="close_btn"
                onClick={() => setOpenVideos(false)}
              >
                <CloseIcon />
              </Button>
              <Swiper
                className="location_swiper gallery_swiper"
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination",
                  dynamicBullets: true,
                  clickable: true,
                }}
                loop
                observer
              >
                <div className="swiper_action">
                  <div className="swiper-button-prev">
                    <ArrowBackIcon />
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next">
                    <ArrowForwardIcon />
                  </div>
                </div>
                {propertyData?.propertyVideos?.length
                  ? propertyData?.propertyVideos?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <figure>
                          <video autoPlay muted loop playsInline preload="yes">
                            <source
                              src={item || "/images/property-detail-01.jpg"}
                              type="video/mp4"
                            />
                          </video>
                        </figure>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Dialog
        open={openQueryModal}
        onClose={handleClose}
        className="revert_dialog"
      >
        <DialogContent>
          <Typography className="dialog_title">Revert to Report</Typography>
          <Typography mb={1} pt={1}>
            {reportMsg || ""}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="message"
                type="text"
                placeholder="Type here..."
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.message}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.message && formik.errors.message}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_danger" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {/* review modal */}
      <Dialog
        open={reviewModal}
        onClose={() => setReviewModal(false)}
        className="revert_dialog"
      >
        <DialogContent>
          <Typography
            className="dialog_title"
            // style={{ color: "rgb(205, 255, 0)" }}
          >
            Reviews
          </Typography>
          <div className="div_parent">
            <ul>
              {reviewData?.length
                ? reviewData?.map((item) => (
                    <li key={item?._id}>
                      <figure>
                        <img src={item?.profilePic || ""} alt="" />
                      </figure>
                      <div className="user_detail">
                        <h3>{item?.userName}</h3>
                        <Rating
                          initialValue={item?.rating}
                          iconsCount={5}
                          size={15}
                          allowHover={false}
                          readonly
                        />
                        <p>{item?.review || ""}</p>
                      </div>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default PropertyDetails;
