import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";

const ManageChangeRequests = () => {
  const navigate = useNavigate();

  function createData(Name: string, investmentType: string) {
    return { Name, investmentType };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Luxury villa in Range Park", "Profit Sharing"),
    createData("Luxury villa in Range Park", "Ownership"),
  ];
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Change Requests</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
            />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>User Details</TableCell>
                  <TableCell>Property Name</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Investment Type</TableCell>
                  {/* <TableCell>Total Tokens</TableCell>
                  <TableCell>Tokens Sold</TableCell>

                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>
                      <p style={{ margin: 0, padding: "2px 0" }}>User</p>
                      <p style={{ margin: 0, padding: "2px 0" }}>
                        user@yopmail.com
                      </p>
                    </TableCell>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell> California, CA, USA</TableCell>
                    <TableCell>{row.investmentType}</TableCell>
                    {/* <TableCell align="center">
                      {" "}
                      {row.investmentType === "Profit Sharing" ? "100" : "-"}
                    </TableCell>

                    <TableCell align="center">
                      {" "}
                      {row.investmentType === "Profit Sharing" ? "10" : "-"}
                    </TableCell>
                    <TableCell>
                      <Switch {...label} defaultChecked size="small" />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate("/manage-properties/details", {
                              state: { propertyType: row?.investmentType },
                            })
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => navigate("/manage-properties/edit")}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageChangeRequests;
