import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import MainContainer from "../../layout/MainContainer";
import { Loader, showError } from "../../constants";
import { NewsByIdResponse } from "../../types/General";
import moment from "moment";
import { useLazyGetNewsByIdQuery } from "../../services/news";

const NewsDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [newsData, setNewsData] = useState<NewsByIdResponse>();

  const [getNewsById, { isLoading }] = useLazyGetNewsByIdQuery();

  const getNewsDetail = async (id: string) => {
    try {
      const response = await getNewsById({
        news_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setNewsData(response?.data[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getNewsDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>News Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-news");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={12}>
                <figure className="blog_img">
                  <img src={newsData?.newsImage || ""} alt="" />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Title</Typography>
                      <Typography component="p">
                        {newsData?.title || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Date</Typography>
                      <Typography component="p">
                        {moment(newsData?.createdAt).format("DD/MM/YYYY") ||
                          "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Status</Typography>
                      <Typography component="p">
                        {newsData?.isBlocked ? "Deactivated" : "Active"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p" sx={{ wordBreak: "break-all" }}>
                        {newsData?.description || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default NewsDetails;
